import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { EditOutlined, DeleteOutlined, UsergroupAddOutlined, ExclamationCircleFilled } from '@ant-design/icons';

import { Table, Input, Row, Col, notification, Select, Button, Spin, Space, Modal } from 'antd';

import styled from "styled-components";
import { assignPage, getListTeamMembers, onDeleteTeamMemberInPage } from "../../api/teams";
import FormAssignPage from "./FormAssignPage";
import { useLocation } from "react-router-dom";

interface IndexProps {
  className?: string;
}
const { confirm } = Modal;


const Index = (props: IndexProps) => {
  const { state } = useLocation();
  const { team } = state; // nhận từ navigate

  const [data, setData] = useState<any>([]);
  const [showModalEdit, setShowModelEdit] = useState(false)
  const [showModalCreate, setShowModelCreate] = useState(false)
  const [itemSelected, setItemSelected] = useState(null)

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [q, setQ] = useState('');


  const getData = async (page: number, limit: number) => {
    setLoading(true)
    const data = {
      page,
      limit,
      teamId: team.id,
    }
    setData([])
    const res: any = await getListTeamMembers(data);
    setLoading(false)
    if (res?.success) {
      setData(res.data.items)
      setTotal(res.data.total)
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại' });
    }
  }


  const handleChangePage = (evt: number, size: number) => {
    onSearch(evt, size);
  };


  const showTotal = (total: any, range: any) => {
    return `${range[0]}-${range[1]} of ${total}`;
  };

  const onSearch = async (page: number, limit: number) => {
    setLoading(true)
    const data = {
      page: page,
      limit: limit,
      q: q,
      teamId: team.id,

    }
    setData([])
    const res: any = await getListTeamMembers(data);
    setLoading(false)
    if (res?.success) {
      setData(res.data.items)
      setTotal(res.data.total)
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại' });
    }
  }

  const onCreate = async (data: any) => {
    const payload = {
      teamId: team.id,
      userId: data?.memberSelected?.id,
      role: data?.memberSelected?.role,
      channelIds: data.channelListSelected.map((channel: any) => channel.key),
    }
    const res: any = await assignPage(payload);
    if (res?.success) {
      getData(1, 10);
      setShowModelCreate(false)
      setShowModelEdit(false)
      notification.success({ message: 'Gán thành viên thành công' });
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại' });
    }
  }

  const onDelete = async (item: any) => {
    confirm({
      title: 'Bạn gỡ thành viên này khỏi page ?',
      icon: <ExclamationCircleFilled />,
      async onOk() {
        const payload = {
          teamId: team.id,
          userId: item?.user?.id,
        };
        const res: any = await onDeleteTeamMemberInPage(payload);
        console.log("res", res)
        if (res?.success) {
          getData(1, 10);
          notification.success({ message: 'Gỡ thành viên này khỏi page thành công' });
        } else {
          notification.error({ message: res?.data?.message?.error || 'Lỗi vui lòng thử lại' });
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });

  }

  useEffect(() => {
    getData(1, 10);
  }, []);

  const columns = [
    {
      title: 'Tên thành viên',
      dataIndex: ['user', 'name'],
      key: 'user.name',
      width: 150,
    },
    {
      title: 'Các Page Quản trị',
      dataIndex: 'channels',
      key: 'channels',
      render: (item: any) => (
        <div>
          {
            item.slice(0, 2).map((channel: any) => (
              <div key={channel?.id} className="pb-2">
                <img
                  src={channel?.picture}
                  alt="channel.name"
                  style={{ width: 30, height: 30, marginRight: 8, borderRadius: 5 }}
                />
                {channel.name}
              </div>
            ))
          }
          {item.length > 2 && (
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 30, height: 25, backgroundColor: '#f0f0f0', borderRadius: 5 }}>
              <span style={{ fontSize: 12 }}>+{item.length - 2}</span>
            </div>
          )}
        </div>
      ),
    },

    {
      title: 'Action',
      dataIndex: '',
      key: '',
      width: 160,
      render: (value: any, item: any) => (
        <Space size="middle">
          <div onClick={() => {
            setShowModelEdit(true)
            setItemSelected(item)
          }} style={{ marginRight: '10px', padding: '5px 10px', cursor: 'pointer' }} >
            <EditOutlined />
          </div>
          <div onClick={() => {
            onDelete(item)
          }} style={{ marginRight: '10px', padding: '5px 10px', cursor: 'pointer' }} >
            <DeleteOutlined />
          </div>
        </Space>
      ),
    },
  ];

  return (
    <div className={props.className}>
      <div className="p-4">
        <div className="filtter">
          <div className="title_page">Gán thành viên vào page</div>
          <Row gutter={12}>
            <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
              <Input allowClear onChange={(e) => { setQ(e.target.value) }} placeholder="Tìm kiếm" />
              <div className="d-flex justify-content-end m-2">
                <div className="d-flex justify-content-end"><Button onClick={() => onSearch(1, 10)} color="primary">Tìm kiếm</Button></div>
              </div>
            </Col>
            <Col span={6}>

            </Col>
            <Col span={6}>
              <div className="d-flex justify-content-end m-2">
                <div className="d-flex justify-content-end"><Button type="primary"
                  onClick={() => {
                    setShowModelCreate(true)
                  }} >Gán thành viên vào page</Button></div>
              </div>
            </Col>
          </Row>
        </div>
        <Table
          loading={loading}
          dataSource={data} columns={columns}
          pagination={{
            showSizeChanger: true,
            total: total,
            onChange: handleChangePage,
            showTotal: showTotal,
          }}
          scroll={{ x: 800, y: 'calc(100vh - 356px)' }}
        />
      </div>
      <Modal width={"90%"} destroyOnClose title="Gán thành viên vào page" open={showModalCreate} footer={null} onCancel={() => { setShowModelCreate(false) }}>
        <FormAssignPage channels={team?.channels} onSave={onCreate} />
      </Modal>
      <Modal width={"90%"} destroyOnClose title="Chỉnh sửa nhóm " open={showModalEdit} footer={null} onCancel={() => { setShowModelEdit(false) }}>
        <FormAssignPage channels={team?.channels} itemSelected={itemSelected} onSave={onCreate} />
      </Modal>

    </div>
  );
};

export default styled(Index)`
  width: 100%;
  overflow: hidden;
  .filtter {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  .title_page {
    margin-bottom: 15px;
    font-weight: 600;
  }

`