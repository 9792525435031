const checkSymbol = () => {
    // Kiểm tra hệ điều hành bằng cách sử dụng `navigator.userAgent`
    const userAgent = navigator.userAgent.toLowerCase();
    const isMac = userAgent.indexOf('mac') !== -1;
    const isWindows = userAgent.indexOf('win') !== -1;

    const keySymbol = isMac ? '⌥' : (isWindows ? 'Alt' : '⌥');  // Nếu trên Mac thì hiển thị '⌥', nếu trên Windows thì hiển thị 'Alt'

    return keySymbol;
};

export { checkSymbol };
