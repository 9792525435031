// components
import {
    TagOutlined,
    TagFilled,
} from '@ant-design/icons';
import { checkSymbol } from "../utils";

// styles
import styled from "styled-components";

interface IndexProps {
    showAlt: boolean;
    character: string;
    numberTag: string;
    tagFinded: any;
}
const Index = ({
    showAlt,
    character,
    numberTag,
    tagFinded,
}: IndexProps) => {

    if (!showAlt) return null;

    return (
        <div style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: -500,
            bottom: 0,
            left: 0,
            right: 0,
        }}>
            <div style={{
                backgroundColor: '#a7a7a7',
                padding: '10px 16px',
                borderRadius: 12
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    {
                        !numberTag ? (
                            <div style={{
                                color: 'white',
                                fontSize: 22,
                                fontWeight: '500'
                            }}>
                                Giữ
                            </div>
                        ) : null
                    }
                    <div style={{
                        backgroundColor: 'gray',
                        margin: numberTag ? '0 14px 0 0' : '0 14px',
                        padding: '4px 16px',
                        borderRadius: 10,
                        color: 'white',
                        fontSize: 20,
                        fontWeight: '500'
                    }}>
                        {checkSymbol()}
                    </div>
                    {
                        character ? (
                            <>
                                <div style={{
                                    color: 'white',
                                    fontSize: 22,
                                    fontWeight: '500'
                                }}>
                                    +
                                </div>
                                <div style={{
                                    backgroundColor: 'gray',
                                    margin: '0 14px',
                                    padding: '4px 10px',
                                    borderRadius: 10,
                                    color: 'white',
                                    fontSize: 20,
                                    fontWeight: '500'
                                }}>
                                    {'`'}
                                </div>
                            </>
                        ) : null
                    }
                    {
                        numberTag ? (
                            <>
                                <div style={{
                                    color: 'white',
                                    fontSize: 22,
                                    fontWeight: '500'
                                }}>
                                    +
                                </div>
                                <div style={{
                                    backgroundColor: 'gray',
                                    margin: '0 14px',
                                    padding: '4px 10px',
                                    borderRadius: 10,
                                    color: 'white',
                                    fontSize: 20,
                                    fontWeight: '500'
                                }}>
                                    {numberTag}
                                </div>
                                <div style={{
                                    color: 'white',
                                    fontSize: 22,
                                    fontWeight: '500'
                                }}>
                                    =
                                </div>
                                {
                                    tagFinded ? (
                                        <div style={{
                                            display: 'flex',
                                            backgroundColor: 'gray',
                                            marginLeft: 14,
                                            padding: '4px 16px',
                                            borderRadius: 10,
                                            color: 'white',
                                            fontSize: 20,
                                            fontWeight: '500'
                                        }}>
                                            <TagFilled
                                                style={{
                                                    transform: 'rotate(270deg)',
                                                    marginRight: 8,
                                                    color: tagFinded?.color
                                                }}
                                            />
                                            {tagFinded?.name}
                                        </div>
                                    ) : (
                                        <div style={{
                                            display: 'flex',
                                            backgroundColor: 'gray',
                                            marginLeft: 14,
                                            padding: '4px 16px',
                                            borderRadius: 10,
                                            color: '#d0d0d0',
                                            fontSize: 20,
                                            fontWeight: '500'
                                        }}>
                                            <TagOutlined
                                                style={{
                                                    transform: 'rotate(270deg)',
                                                    marginRight: 8,
                                                }}
                                            />
                                            Không tồn tại
                                        </div>
                                    )
                                }
                            </>
                        ) : (
                            <>
                                <div style={{
                                    color: 'white',
                                    fontSize: 22,
                                    fontWeight: '500'
                                }}>
                                    +
                                </div>
                                <div style={{
                                    backgroundColor: 'gray',
                                    marginLeft: 14,
                                    padding: '4px 16px',
                                    borderRadius: 10,
                                    color: 'white',
                                    fontSize: 20,
                                    fontWeight: '500'
                                }}>
                                    Số thẻ
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default styled(Index)`
`
