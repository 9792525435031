import React, { useState, useEffect, useCallback } from "react";
import { createSelector } from "reselect";
import { Form } from "reactstrap";
import { useRedux } from "../../../hooks/index";
import { useSearchParams } from "react-router-dom";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  changeSelectedChat,
  getMessagesHistories,
  changeSelectedConversation,
  chatsApiResponseSuccess,
  uploadListImageDraft,
  changeConversationFilterState,
  updateReplyMessage,
  onChangeTextTranslateInput,
  onReadedMess
} from "../../../redux/actions";
import { getTagListAll } from "../../../api/tags";
import ConversationList from "./ConversationList";
import SwitchGroup from "./SwitchGroup";
import styled, { createGlobalStyle } from "styled-components";
import { getDetailConversation, readConversationId, updateTranslationsConversation } from "../../../api/conversations";
import { ChatsActionTypes, ConversationFilterState } from "../../../redux/chats/types";
import FilterConversation from "./FilterConversation";
import { AutoComplete, Input, Button, Popover, Menu, Tag, Checkbox, Badge, Spin, List, Radio } from "antd";
import IconFilter from "../../../assets/images/tags_filter/filter.svg";
import IconSearch from "../../../assets/images/tags_filter/search.svg";
import IconTag from "../../../assets/images/tags_filter/tags_icon.svg";
import IconCondition from "../../../assets/images/tags_filter/condition.svg";
import IconNoneTag from "../../../assets/images/tags_filter/none_tags_icon.svg";
import RightArrow from "../../../assets/images/tags_filter/right_arrow.svg";
import _ from "lodash";
let inputTimer: any = null;
interface IndexProps {
  className?: string;
}
const GlobalStyle = createGlobalStyle`
  .ant-popover-inner {
    padding: 0 !important;
  }
  .ant-menu-item {
    padding-left:0 !important;
    height: 32px !important;
  }
  .ant-tag {
    border-radius: 10px;
  }
    .ant-select-dropdown {
    box-shadow: none;
    height:490px;
  }
  .rc-virtual-list-holder {
    max-height:490px !important; 
  }
  .ant-list-split .ant-list-item {
    border-block-end: none;
  }
  .ant-list-split .ant-list-item {
    width:100%
  }
  .ant-badge .ant-badge-count {
    margin-left: auto;
    background-color: #1890ff;
    border-radius: 12px;
    color: #fff;
    padding: 2px 0px;
    font-size: 12px;
    line-height: 15px;
  }
  .condition {
    font-size: 12px;
    padding: 0px 6px;
    border-radius: 12px;
    color: rgb(29, 41, 57);
    background-color: rgb(234, 236, 240);
    border: 1px solid rgb(208, 213, 221);
    font-weight:400;
    color:#1D2939;
  }
  .ant-radio-wrapper .ant-radio-input {
    position: absolute;
    inset: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 1;
}
`;

//hungfff

const Index: React.FC<IndexProps> = (props: IndexProps) => {
  const { className } = props;
  const { dispatch, useAppSelector } = useRedux();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('groupId');
  const channelSelected = searchParams.get('channelId');
  const state = createSelector(
    (props: any) => props.Chats,
    (props) => ({
      selectedChat: props.selectedChat,
      conversationList: props.conversationList,
      conversationFilterState: props.conversationFilterState,
      groupInfo: props.groupInfo,
    })
  );
  const { selectedChat, conversationList, conversationFilterState, groupInfo } = useAppSelector(state);
  const [itemTagSelected, setItemTagSelected] = useState<any>(null)
  const [searchFocus, setSearcFocus] = useState<boolean>(false);
  const [checkedTags, setCheckedTags] = useState<string[]>([]);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [exceptTags, setExceptTags] = useState<string[]>([]);
  const [exceptIds, setExceptIds] = useState<string[]>([]);
  const [tagList, setTagList] = useState<any>([])
  const [exceptTagsList, setExceptTagsList] = useState<any>([])
  const [keyMenu, setKeyMenu] = useState<any>(1)
  const [tagAB, setTagAB] = useState('OR');
  const [value, setValue] = useState("");
  const [fillter, setFillter] = useState<any>({
    isRead: true,
    isSpam: false,
    tags: [],
    exceptTags: [],
    tagFilterMode: null,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreExcept, setHasMoreExcept] = useState(true);
  const [dataAll, setDataAll] = useState([]);
  const [searchTags, setSearchTags] = useState("");
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredTags = tagList.filter((tag: any) =>
    tag?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredExceptTags = exceptTagsList.filter((tag: any) =>
    tag?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCheck = (tag: string) => {
    setCheckedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );

  };
  const handleCheckExcept = (tag: string) => {
    setExceptTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  const onChangetagAB = (e: any) => {
    setTagAB(e.target.value);
  };


  useEffect(() => {
    if (groupId && groupInfo?.channelIds) {
      const result: any[] = [];
      filteredTags.forEach((tag: any) => {
        if (checkedTags.includes(tag.id)) {
          if (Array.isArray(tag?.tagIds)) {
            result.push(...tag.tagIds);
          }
        }
      });
      setCheckedIds(result);
    } else {
      setCheckedIds([]);
    }
  }, [checkedTags, groupInfo?.channelIds, groupId]);



  useEffect(() => {
    if (groupId && groupInfo?.channelIds) {
      const result: any[] = [];
      filteredExceptTags.forEach((tag: any) => {
        if (exceptTags.includes(tag.id)) {
          if (Array.isArray(tag?.tagIds)) {
            result.push(...tag.tagIds);
          }
        }
      });
      setExceptIds(result);
    } else {
      setExceptIds([]);
    }
  }, [exceptTags, groupInfo?.channelIds, groupId]);


  useEffect(() => {
    setExceptIds([])
    setCheckedIds([]);
    setExceptTags([])
    setCheckedTags([])
  }, [groupId]);

  const onRemoveTagFilter = () => {
    setExceptIds([])
    setCheckedIds([]);
    setExceptTags([])
    setCheckedTags([])
  }


  useEffect(() => {
    setFillter({
      tags: groupId ? checkedIds : checkedTags,
      isRead: true,
      isSpam: false,
      exceptTags: groupId ? exceptIds : exceptTags,
      tagFilterMode: tagAB
    })
    dispatch(changeConversationFilterState({
      tags: groupId ? checkedIds : checkedTags,
      isRead: true,
      isSpam: false,
      exceptTags: groupId ? exceptIds : exceptTags,
      tagFilterMode: tagAB,
      type: ''
    }));
  }, [exceptTags, checkedTags, tagAB, dispatch, checkedIds, exceptIds])


  const getTagListData = async () => {
    try {
      const response: any = await getTagListAll({
        isAll: groupId ? undefined : true,
        channelId: groupId ? groupInfo?.channelIds?.toString() : channelSelected,
        isGroup: groupId ? true : undefined,
        q: searchTags
      });
      if (response?.success) {
        setDataAll(response?.data?.items?.sort((a: any, b: any) => a.sequence - b.sequence))
        setTagList(([{ id: 'untagged', name: 'Không gắn thẻ', color: '#fff', tagIds: ['untagged'] }, ...response?.data?.items?.sort((a: any, b: any) => a.sequence - b.sequence)]))
        setExceptTagsList(response?.data?.items?.sort((a: any, b: any) => a.sequence - b.sequence))
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    if ((groupId && groupInfo?.channelIds) || channelSelected) {
      getTagListData()
    }
  }, [groupInfo?.channelIds, channelSelected, groupId, searchTags])

  const loadMoreData = () => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      const start = tagList.length;
      const end = start + 100;
      const newData = dataAll.slice(start, end);

      setTagList([...tagList, ...newData]);

      if (end >= dataAll.length) {
        setHasMore(false)
      }
      setLoading(false);
    }, 500);
  };

  const loadMoreExceptTags = () => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      const start = exceptTagsList.length;
      const end = start + 100;
      const newData = dataAll.slice(start, end);

      setExceptTagsList([...exceptTagsList, ...newData]);

      if (end >= dataAll.length) {
        setHasMoreExcept(false)
      }
      setLoading(false);
    }, 500);
  };

  const onChangeConversationFilterState = (filterState: ConversationFilterState) => {
    dispatch(changeConversationFilterState({
      ...filterState,
      type: conversationFilterState?.type
    }));
  }

  const onSelectConversation = async (obj: any) => {
    const id: string = obj?.conversationId;
    dispatch(chatsApiResponseSuccess(
      ChatsActionTypes.GET_MESSAGES_HISTORIES,
      {
        items: [],
        total: 0,
        page: 0,
      }
    ));
    dispatch(changeSelectedConversation(obj));

   
    dispatch(changeSelectedChat(id));
    dispatch(uploadListImageDraft([]));
    dispatch(updateReplyMessage({}))
    dispatch(onChangeTextTranslateInput({}))

    const res = await getDetailConversation({ conversationId: obj?.conversationId });
    await dispatch(changeSelectedConversation({
      ...obj,
      extraInfo: {
        translation: {
          ...res?.data?.extraInfo?.translation,
          translateReceivedTo: res?.data?.extraInfo?.translation?.translateReceivedTo || 'vi',
          translateSentTo: res?.data?.extraInfo?.translation?.translateSentTo,
        }
      },
      readed: true,
    }));
    dispatch(getMessagesHistories({
      id,
      page: 1,
      limit: 20,
    }));

    dispatch(onReadedMess({}))

    // Trường hợp chưa cấu hình dịch
    if(!res?.data?.extraInfo?.translation?.translateReceivedTo || !res?.data?.extraInfo?.translation?.translateSentTo) {
      const payload = {
        autoTranslateReceived: true,
        showReceivedMessage: true,
        translateReceivedTo: res?.data?.extraInfo?.translation?.translateReceivedTo || 'vi',
        autoTranslateSent: true,
        showSentMessage: true,
        translateSentTo: res?.data?.extraInfo?.translation?.translateSentTo,
        conversationId: id,
      }
      const resUpdateTranslation: any = await updateTranslationsConversation(payload);
      if (resUpdateTranslation?.success) {
        dispatch(changeSelectedConversation({
            ...obj,
            extraInfo: {
              translation: resUpdateTranslation?.data || {},
            },
            readed: true,
          })
        );
      }
  }
 


    if (!obj?.readed) {
      const newConversationList = conversationList?.items?.map((conversation: any) => {
        if (conversation?.conversationId === id) {
          return { ...conversation, readed: true, unreadCount: 0 };
        } else return conversation;
      });

      dispatch(chatsApiResponseSuccess(
        ChatsActionTypes.GET_CONVERSATION_LIST,
        {
          total: conversationList.total,
          page: conversationList.page,
          items: [...newConversationList],
        },
      ));
      await readConversationId({ conversationId: obj?.conversationId, readed: true });
    }
  };

  const searchConversation = _.debounce((value: string) => {
    setFillter({
      ...fillter,
      search: value,
    });
    dispatch(changeConversationFilterState({
      ...conversationFilterState,
      search: value,
    }));
  }, 300)


  const handlePressEnter = useCallback(async () => {
    if (inputTimer) {
      clearTimeout(inputTimer);
    }
    inputTimer = setTimeout(async () => {
      setFillter({
        ...fillter,
        search: value,
      });
      dispatch(changeConversationFilterState({
        ...conversationFilterState,
        search: value,
      }));
    }, 500);
  }, [value])

  useEffect(() => {
    if (value=== "") {
      setFillter({
        ...fillter,
        search: "",
      });
      dispatch(changeConversationFilterState({
        ...conversationFilterState,
        search: "",
      }));
    }
  }, [value])

  const handleInputChange = (e: any) => {
    setValue(e);
  };

  // const onSearchTags = _.debounce((value: string) => {
  //   setSearchTags(value);
  // }, 300)


  const onClick = (e: any) => {
    setKeyMenu(e.key)
  };

  const onClickDK = (e: any) => {
    setKeyMenu(e.key)
  };

  const content = () => {
    return (
      <div style={{ width: 490, backgroundColor: '#F9FAFB' }} >
        <div style={{ padding: 4, display: 'flex' }}>
          <div style={{ width: '45%' }}>
            <div style={{ fontSize: 12, color: '#667085', fontWeight: 500, padding: '2px 8px' }}>Thẻ hội thoại</div>
            <Menu
              defaultSelectedKeys={['1']}
              style={{ backgroundColor: '#F9FAFB', borderInlineEnd: 'none' }}
              mode="inline"
              onClick={onClick}
            >
              <Menu.Item key="1">
                <div style={{ alignItems: 'center', display: 'flex', margin: '0 10px' }}>
                  <img src={IconTag} style={{ marginRight: 10 }} alt="" />
                  <span style={{ color: `rgb(102, 112, 133)`, fontWeight: 400 }}>Có chứa thẻ </span>

                  <span style={{ position: 'absolute', right: 10, display: 'flex' }} >
                    {
                      checkedTags.length > 0 ?
                        <Badge count={checkedTags.length} className="badge" />
                        : ""
                    }
                    <img src={RightArrow} alt="" /></span>
                </div>
              </Menu.Item>
              <Menu.Item key="2">
                <div style={{ alignItems: 'center', display: 'flex', margin: '0 10px' }}>
                  <img src={IconNoneTag} style={{ marginRight: 10 }} alt="" />
                  <span style={{ color: `rgb(102, 112, 133)`, fontWeight: 400 }}>Loại trừ thẻ</span>
                  <span style={{ position: 'absolute', right: 10, display: 'flex' }} >
                    {
                      exceptTags?.length > 0 ?
                        <Badge count={exceptTags?.length} className="badge" />
                        : ""
                    }
                    <img src={RightArrow} alt="" /></span>
                </div>
              </Menu.Item>
            </Menu>
            <div style={{ fontSize: 12, color: '#667085', fontWeight: 500, padding: '2px 8px' }}>Điều kiện</div>
            <Menu
              style={{ backgroundColor: '#F9FAFB', borderInlineEnd: 'none' }}
              mode="inline"
              onClick={onClickDK}
            >
              <Menu.Item key="3">
                <div style={{ alignItems: 'center', display: 'flex', margin: '0 10px' }}>
                  <img src={IconCondition} style={{ marginRight: 10 }} alt="" />
                  <span style={{ color: `rgb(102, 112, 133)`, fontWeight: 400 }}>Điều kiện</span>

                  <span style={{ position: 'absolute', right: 20, display: 'flex' }} >
                    {
                      tagAB === "AND" ?
                        <div style={{ color: '#1890FF', fontWeight: 400 }}>VÀ</div>
                        : <div style={{ color: '#B37FEB', fontWeight: 400 }}>HOẶC</div>
                    }
                    <img src={RightArrow} alt="" /></span>
                </div>
              </Menu.Item>
            </Menu>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '55%', height: "100%", backgroundColor: '#fff' }}>
            {keyMenu !== "3" ?
              <>
                <Input
                  placeholder="Tìm kiếm thẻ"
                  prefix={<img src={IconSearch} style={{ width: 20, height: 20 }} alt="IconSearch" />}
                  // value={searchTags}
                  onChange={handleSearch}
                // onChange={(e) => onSearchTags(e.target.value)}
                />
                {keyMenu == '1' ?
                  <div style={{ padding: 8, maxHeight: 380, overflow: 'auto' }}>
                    <List
                      dataSource={filteredTags}
                      style={{ border: 'none' }}
                      renderItem={(item: any) => (
                        <List.Item style={{ width: '100%', padding: '6px 0px' }}>
                          <Checkbox
                            checked={checkedTags.includes(item.id)}
                            onChange={() => handleCheck(item?.id)}
                            style={{ width: '100%' }}>
                            <span style={{
                              backgroundColor: item?.color,
                              borderRadius: 10,
                              padding: '2px 8px',
                              fontSize: 12,
                              width: '100%',
                              border: item.id !== "untagged" ? 'none' : "1px dashed #D0D5DD",
                              color: item.id !== "untagged" ? '#fff' : "#667085"
                            }}>
                              {item.name}</span>
                          </Checkbox>
                        </List.Item>
                      )}
                    />
                    {/* {loading && (
                      <div style={{ textAlign: "center", marginTop: 12 }}>
                        <Spin />
                      </div>
                    )}
                    {hasMore && !loading &&  dataAll.length > 200 && (
                      <div style={{ textAlign: "center", marginTop: 12 }}>
                        <Button onClick={loadMoreData} type="primary" size="small" >Load More</Button>
                      </div>
                    )}
                    {!hasMore && (
                      <p style={{ textAlign: "center", marginTop: 12 }}>Đã tải hết dữ liệu</p>
                    )} */}
                  </div>
                  :
                  <div style={{ padding: 8, maxHeight: 380, overflow: 'auto' }}>
                    <List
                      dataSource={filteredExceptTags}
                      style={{ border: 'none' }}
                      renderItem={(item: any) => (
                        <List.Item style={{ width: '100%', padding: '6px 0px' }}>
                          <Checkbox
                            checked={exceptTags.includes(item.id)}
                            onChange={() => handleCheckExcept(item?.id)}
                            style={{ width: '100%' }}>
                            <span style={{
                              backgroundColor: item?.color,
                              borderRadius: 10,
                              padding: '2px 8px', fontSize: 12,
                              width: '100%',
                              color: item.id !== "untagged" ? '#fff' : "#667085"
                            }}>{item.name}</span></Checkbox>
                        </List.Item>
                      )}
                    />
                    {/* {loading && (
                      <div style={{ textAlign: "center", marginTop: 12 }}>
                        <Spin />
                      </div>
                    )}
                    {hasMoreExcept && !loading && dataAll.length > 200 && (
                      <div style={{ textAlign: "center", marginTop: 12 }}>
                        <Button onClick={loadMoreExceptTags} type="primary" size="small">Load More</Button>
                      </div>
                    )}
                    {!hasMoreExcept && (
                      <p style={{ textAlign: "center", marginTop: 12 }}>Đã tải hết dữ liệu</p>
                    )} */}
                  </div>
                }
              </> :
              <Radio.Group style={{ fontWeight: 400, fontSize: 12, justifyContent: 'space-between' }} value={tagAB} onChange={onChangetagAB}>
                <Radio value={'AND'} >
                  <span className="condition">Thẻ A </span>
                  <span style={{ textTransform: 'uppercase', color: '#1890FF', margin: 25 }}>và</span>
                  <span className="condition">Thẻ B</span>
                </Radio>
                <Radio value={'OR'}>
                  <span className="condition">Thẻ A </span>
                  <span style={{ textTransform: 'uppercase', color: '#B37FEB', margin: 15 }}>hoặc</span>
                  <span className="condition">Thẻ B</span>
                </Radio>
              </Radio.Group>
            }
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={className}>
      <GlobalStyle />
      <div style={{ padding: '6px 8px' }}>
        {/* <Form> */}
        <div className="mb-3" style={{ display: 'flex' }}>
          <AutoComplete
            popupClassName="certain-category-search-dropdown"
            popupMatchSelectWidth={390}
            style={{
              width: !searchFocus ? 'calc(100% - 128px - 8px)' : '100%',
            }}
            options={[]}
          >
            <Input
              className="custom_input"
              allowClear
              prefix={<img src={IconSearch} style={{ width: 20, height: 20 }} alt="" />}
              placeholder="Tìm kiếm"
              onFocus={() => setSearcFocus(!searchFocus)}
              onBlur={() => setSearcFocus(!searchFocus)}
              onPressEnter={handlePressEnter}
              // onChange={(e) => searchConversation(e.target.value)}
              value={fillter?.search}
              onChange={(e) => handleInputChange(e.target.value)}
            />
          </AutoComplete>
          <div className="filter_tags" style={{ display: !searchFocus ? "" : "none" }}>
            <Popover placement="bottomLeft" title={[]} content={content} trigger="click" className="custom" style={{ height: 400 }}>
              <Button className="button_tags">
                <img src={IconFilter} style={{ width: 20, height: 20, marginRight: 5 }} alt="" />&nbsp;
                {
                  checkedTags.length > 0 || exceptTags?.length > 0 ?
                    <div><Badge count={(checkedTags.length) + (exceptTags?.length)} className="badge" /> <CloseCircleOutlined onClick={() => onRemoveTagFilter()} style={{ position: 'absolute' }} /> </div>
                    : "Lọc theo"
                }
              </Button>
            </Popover>
          </div>
        </div>
        {/* </Form> */}
      </div>
      <SwitchGroup />
      <FilterConversation
        filter={conversationFilterState}
        itemTagSelected={itemTagSelected}
        setItemTagSelected={setItemTagSelected}
        channelSelected={groupId ? groupInfo?.channelIds?.toString() : channelSelected}
        onChangeFilterState={onChangeConversationFilterState}

      />
      <ConversationList
        fillter={conversationFilterState}
        itemTagSelected={itemTagSelected}
        selectedChat={selectedChat}
        onSelectConversation={onSelectConversation}
      />
    </div>
  );
};

export default styled(Index)`
  .border-team {
    height: 1px;
    background-color: #d0d0d0;
    margin-bottom: 8px;
  }
  .page-list {
    max-height: 280px;
    overflow: auto;
    margin-bottom: 16px;
  }
  .item-option {
    display: flex;
    align-items: center;
    padding: 8px 24px;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  .custom_input {
    height: 32px;
    background: rgb(234, 236, 240);
    border: 1px solid rgb(255, 255, 255);
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: none;
  }
  .custom_input .ant-input::placeholder {
    color: rgb(102, 112, 133);
    font-weight:500;
  }
  .filter_tags{
    float: right;
    margin-left: 8px;
    text-align: center;
    justify-content: center;
    width: 136px;
    box-shadow: none;
    outline: none;
  }
  .filter_tags  .button_tags {
    display: flex;
    align-items: center;
    border: none;
    background-color: rgb(234, 236, 240);
    padding: 5px 8px;
    color: rgb(102, 112, 133);
    width: 100%;
    box-shadow: none;
    outline: none;
    font-weight:500;
  }
  .menu-item {
    display: flex;
    align-items: center;
  }

  .menu-item .ant-tag {
    margin-right: 10px;
  }
  
`;
