import { DateRangePicker } from "react-date-range";
import { addDays, endOfDay, startOfDay } from "date-fns";
import { vi } from "date-fns/locale";
import dayjs from "dayjs";
import { Button, TimePicker } from "antd";
import moment from "moment/moment";
import React, { useState } from "react";
import { ConversationFilterState } from "../../redux/chats/types";
import type { TimePickerProps } from 'antd';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import './cssRangePicker.css';
interface Props {
    filterState: ConversationFilterState;
    onChangeFilterState: (filterState: ConversationFilterState) => void;
    setShowDropdownCalendar: (visible: boolean) => void;
    setShowFormTime: (visible: boolean) => void;
}

const DropDownRenderCalendar = ({ filterState, onChangeFilterState, setShowDropdownCalendar,setShowFormTime }: Props) => {
    const format = "DD/MM/YYYY";
    const [isCreate, setIsCreate] = useState<any>(false)
    const [startTime, setStartTime] = useState<Date>(new Date());
    const [endTime, setEndTime] = useState<Date>(new Date());
    const [hourStartTime, setHourStartTime] = useState('00:00:00');
    const [hourEndTime, setHourEndTime] = useState('23:59:59');
    dayjs('23:59:59', 'HH:mm:ss')
    const staticRanges: { label: string, startDate: Date, endDate: Date }[] = [
        {
            label: 'Hôm nay',
            startDate: startOfDay(new Date()),
            endDate: endOfDay(new Date()),
        },
        {
            label: 'Hôm qua',
            startDate: startOfDay(addDays(new Date(), -1)),
            endDate: endOfDay(addDays(new Date(), -1)),
        },
        {
            label: '7 ngày trước',
            startDate: startOfDay(addDays(new Date(), -7)),
            endDate: endOfDay(new Date()),
        },
        {
            label: '30 ngày trước',
            startDate: startOfDay(addDays(new Date(), -30)),
            endDate: endOfDay(new Date()),
        },
        {
            label: '90 ngày trước',
            startDate: startOfDay(addDays(new Date(), -90)),
            endDate: endOfDay(new Date()),
        },
    ];
    const onChangeStart: TimePickerProps['onChange'] = (time, timeString: any) => {
        setHourStartTime(timeString)
    };

    const onChangeEnd: TimePickerProps['onChange'] = (time, timeString: any) => {
        setHourEndTime(timeString)
    };


    return (
        <div style={{ background: '#fff', borderRadius: 10, boxSizing: 'border-box', display: 'flex', flexDirection: 'column'}}>
            <div className="date-range-container">
                <div className="filter-header">
                    <span>Lọc theo khoảng thời gian</span>
                    <div className="filter-options">
                        <Button size="small" style={{ color: isCreate ? "#FFF" : '#000', backgroundColor: isCreate ? localStorage.getItem('colorTheme') || '#00aaff' : '' }} className="filter-option" onClick={() => setIsCreate(true)}>Thời gian tạo</Button>
                        <Button size="small" style={{ color: !isCreate ? "#FFF" : '#000', backgroundColor: !isCreate ? localStorage.getItem('colorTheme') || '#00aaff' : '' }} className="filter-option" onClick={() => setIsCreate(false)}>Thời gian cập nhật</Button>
                    </div>
                </div>
                <DateRangePicker
                    ranges={[{
                        startDate: startTime ?? startOfDay(new Date()),
                        endDate: endTime ?? endOfDay(new Date()),
                        key: "selection"
                    }]}
                    months={2}
                    // locale={vi}
                    onChange={(rangesByKey) => {
                        setStartTime(rangesByKey["selection"]["startDate"] as Date);
                        setEndTime(rangesByKey["selection"]["endDate"] as Date);
                    }}
                    staticRanges={staticRanges.map((item) => ({
                        label: item.label,
                        range: () => ({ startDate: item.startDate, endDate: item.endDate }),
                        isSelected: (range) => dayjs(range.startDate).isSame(dayjs(item.startDate)) && dayjs(range.endDate).isSame(dayjs(item.endDate)),
                    }))}
                    moveRangeOnFirstSelection={true}
                    inputRanges={[]}
                    rangeColors={['rgb(52, 73, 94)']}
                    showPreview={false}
                    showMonthAndYearPickers={false}
                    startDatePlaceholder="Từ ngày"
                    endDatePlaceholder="Đến ngày"
                    direction="horizontal"
                />

                <div className="d-flex justify-content-end align-items-end column-gap-1">
                    <div>
                        {startTime !== undefined ? <div>{moment(startTime).format("DD/MM/YYYY")}&nbsp;
                            <TimePicker showNow={false} allowClear={false} value={dayjs(hourStartTime, 'HH:mm:ss')} style={{ width: 90 }} onChange={onChangeStart} changeOnScroll needConfirm={false} size="small" /> -&nbsp;
                            {moment(endTime).format("DD/MM/YYYY")}&nbsp;
                            <TimePicker showNow={false} allowClear={false} value={dayjs(hourEndTime, 'HH:mm:ss')} style={{ width: 90 }} onChange={onChangeEnd} changeOnScroll needConfirm={false} size="small" /> </div> : ''}
                    </div>
                    <Button
                        style={{ boxShadow: "revert-layer", backgroundColor: '#8eb4cb', color: '#fff' }}
                        onClick={() => {
                            onChangeFilterState({
                                ...filterState,
                                startTime: undefined,
                                endTime: undefined
                            });
                            setStartTime(new Date());
                            setEndTime(new Date());
                            setShowDropdownCalendar(false);
                            setShowFormTime(false)
                        }}
                    >
                        Bỏ qua
                    </Button>
                    <Button
                        style={{ width: 54, boxShadow: "revert-layer", backgroundColor: '#279f43', color: '#fff' }}
                        onClick={() => {
                            console.log("hourStartTime", hourStartTime)
                            console.log("startTime", moment(startTime).format(format) + " " + hourStartTime)
                            onChangeFilterState({
                                ...filterState,
                                startTime: startTime ? moment(startTime).format(format) + " " + hourStartTime : "",
                                endTime: endTime ? moment(endTime).format(format) + " " + hourEndTime : "",
                                timeFilterMode : isCreate ? 'CREATED' :'UPDATED', 
                            });
                            setShowDropdownCalendar(false);
                            setShowFormTime(false)
                        }}
                    >
                        Lọc
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default DropDownRenderCalendar;