import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getQuickRepliesList = (params: any) => {
    return api.get(url.QUICk_REPLIES, { params: params });
};

const addQuickReplies = (data: any) => {
    return api.create(url.QUICk_REPLIES, data);
};

const deleteQuickReplies = (quickRepliesId: string) => {
    return api.delete(url.QUICk_REPLIES + "/" + quickRepliesId);
};

const deleteMultiQuickReplies = (quickRepliesIds: any) => {
    return api.delete(url.QUICk_REPLIES, {
        ids: quickRepliesIds,
    });
};

const updateQuickReplies = (quickRepliesId: string, data: any) => {
    return api.update(url.QUICk_REPLIES + "/" + quickRepliesId, data);
};

const updateAllQuickReplies = (data: any) => {
    return api.update(url.QUICk_REPLIES + "/updates", data);
};

const downloadTemplate = (data: any) => {
    return api.export(url.QUICk_REPLIES + "/template-xlsx", data);
}

const exportQuickReplies = (data: any) => {
    return api.export(url.QUICk_REPLIES + "/export-xlsx", data);
}

const importQuickReplies = (data: any) => {
    return api.create(url.QUICk_REPLIES + "/import", data);
}

const getQuickRepliesTopic = (data: any) => {
    return api.create(url.QUICK_REPLIES_TOPIC, data);
}

const addTopic = (id: string, data: any) => {
    return api.create(url.QUICK_REPLIES_TOPIC + "/" + id, data);
};

const updateTopicAll = (channelId: string, data: any) => {
    return api.update(url.QUICK_REPLIES_TOPIC + "/" + channelId, data);
};

const deleteQuickRepliesTopic = (topicId: string) => {
    return api.delete(url.QUICK_REPLIES_TOPIC, {
        data: {
            ids: [topicId],
        },
    });
};

const syncQuickReply = (data: any) => {
    return api.create(url.QUICk_REPLIES + url.SYNC, data);
};

const uploadQuickReplies = (formData: any) => {
    return api.createWithFile(url.QUICk_REPLIES + "/upload", formData);
};

export {
    getQuickRepliesList,
    addQuickReplies,
    deleteQuickReplies,
    deleteMultiQuickReplies,
    updateQuickReplies,
    updateAllQuickReplies,
    downloadTemplate,
    exportQuickReplies,
    importQuickReplies,
    getQuickRepliesTopic,
    addTopic,
    updateTopicAll,
    deleteQuickRepliesTopic,
    syncQuickReply,
    uploadQuickReplies,
};
