import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

// api
import {
    getPageListConnected,
    cancelPage
  } from "../../../api/index";
  
// component
import { SearchOutlined, CheckSquareTwoTone } from '@ant-design/icons';
import { Col, Row, Input, Checkbox, Tooltip } from 'antd';
import iconFb from "../../../assets/images/users/ic_fb.png";

interface IndexProps {
    groupKey: string;
    groups: any;
    onSetGroups: (key: string, groups: any) => void;
}

const ChannelList = (props: IndexProps) => {
    const { groups, onSetGroups, groupKey } = props;
    const [channelIds, setChannelIds] = useState<any[]>([]);
    const [allPage, setAllPage] = useState<any[]>([]);
    const [pageList, setPageList] = useState<any>(null);
    const [valueSearch, setValueSearch] = useState<string>('');
    const [checked, setChecked] = useState<boolean>(false);

    useEffect(() => {
        // fetch data
        if (pageList === null) {
          getPageListData({valueSearch: valueSearch});
        }
      }, []);
    
      const getPageListData = async (data: any) => {
        try {
          const response: any = await getPageListConnected({ isAll: true, q: data?.valueSearch });
          if (response?.data?.items) {
            const arr = response.data.items;
            setPageList({
              total: response.data.total,
              page: data.page,
              items: arr,
            });
            if (data?.valueSearch?.length == 0 && allPage?.length == 0) {
                setAllPage(arr);
            }
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    
    useEffect(() => {
        if (groups.length > 0 && allPage?.length > 0) {
            const ids = groups.find((item: any) => item?.key === groupKey)?.channelIds;
            setChannelIds(ids);
            if (ids?.length === allPage?.length) {
                setChecked(true);
            } else {
                setChecked(false);
            }

        }
    }, [groups, allPage]);

    const onChange = (e: any) => {
        if (e.target.checked) {
            setChecked(true);
            setChannelIds(allPage?.map((item: any) => item.id));
            const arr = groups.map((item: any) => {
                if (item.key === groupKey) {
                    return {
                        ...item,
                        channelIds: allPage?.map((item: any) => item.id),
                    }
                }
                return item;
            });
            onSetGroups(groupKey, arr);
        } else {
            setChecked(false);
            setChannelIds([]);
            const arr = groups.map((item: any) => {
                if (item.key === groupKey) {
                    return {
                        ...item,
                        channelIds: [],
                    }
                }
                return item;
            });
            onSetGroups(groupKey, arr);
        }
    };

    const onSelect = (id: string) => {
        const index = channelIds.indexOf(id);
        if (index === -1) {
            setChannelIds([...channelIds, id]);
            const arr = groups.map((item: any) => {
                if (item.key === groupKey) {
                    return {
                        ...item,
                        channelIds: [...channelIds, id],
                    }
                }
                return item;
            });
            onSetGroups(groupKey, arr);
        } else {
            setChannelIds(channelIds.filter((item: any) => item !== id));
            const arr = groups.map((item: any) => {
                if (item.key === groupKey) {
                    return {
                        ...item,
                        channelIds: channelIds.filter((item: any) => item !== id),
                    }
                }
                return item;
            });
            onSetGroups(groupKey, arr);
        }
    };

    const searchPages = (e: any) => {
        const value = e.target.value;
        setValueSearch(value);
        getPageListData({valueSearch: value});
    };

    return (
        <>
            <Row style={{ alignItems: 'center' }}>
                <Col span={8}>
                    <Checkbox
                        onChange={onChange}
                        checked={checked}
                    >Chọn tất cả các trang</Checkbox>
                </Col>
                <Col span={8} offset={8}>
                    <Input
                        placeholder="Nhập tên hoặc ID trang"
                        prefix={<SearchOutlined />}
                        onChange={searchPages}
                        value={valueSearch}
                    />
                </Col>
            </Row>
            <div
                id="page-list-connect"
                style={{
                    marginTop: 16,
                    backgroundColor: "#ecedf4",
                    borderRadius: 8,
                    height: 'calc(100vh - 228px)',
                    overflow: 'auto',
                    padding: '16px 0 16px 16px'
                }}
            >
                {
                    pageList?.items.length > 0 ? (
                        <ul className="list-inline user-chat-nav mb-0">
                            {
                                pageList?.items?.map((item: any, index: number) => {
                                    if (!item.name) return null;
                                    return (
                                        <li
                                            key={index}
                                            className="list-inline-item d-inline-block me-3 ms-0 mb-3"
                                            style={{
                                                width: 'calc((100% - 48px) / 3)',
                                            }}
                                        >
                                            <div
                                                onClick={() => onSelect(item?.id)}
                                                style={{
                                                    position: 'relative',
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    backgroundColor: "white",
                                                    border: '1px solid #d0d0d0',
                                                    padding: "10px",
                                                    borderRadius: 5,
                                                    width: '100%',
                                                }}
                                            >
                                                <img
                                                    src={item?.picture}
                                                    alt=""
                                                    style={{ width: 64, height: 64, marginRight: 8, borderRadius: 5 }}
                                                />
                                                <div style={{display: 'grid'}}>
                                                    <Tooltip title={item?.name}>
                                                        <div style={{
                                                            fontWeight: 'bold',
                                                            marginBottom: 4,
                                                            marginRight: 20,
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis'
                                                        }}>{item?.name}</div>
                                                    </Tooltip>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <img
                                                            src={iconFb}
                                                            alt=""
                                                            style={{ width: 14, height: 14, marginRight: 4 }}
                                                        />
                                                        <span style={{ fontSize: 12 }}>{item?.id}</span>
                                                    </div>
                                                </div>
                                                {
                                                    channelIds.indexOf(item.id) !== -1 ? (

                                                        <div style={
                                                            {
                                                                position: 'absolute',
                                                                right: 8,
                                                                top: 8
                                                            }
                                                        }>
                                                            <CheckSquareTwoTone twoToneColor={localStorage.getItem('colorTheme') || ''} style={{fontSize: 20 }} />
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    ) : null
                }
            </div>
        </>
    );
};

export default ChannelList;
