import moment from "moment";

// hooks
import { useRedux } from "../../../hooks/index";
import { createSelector } from "reselect";

// actions
import {
  getMessagesHistories,
} from "../../../redux/actions";

// components
import {
  Spin
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../components/Loader";
import Message from "./Message";

interface ConversationProps {
}
const MessagesHistories = ({
}: ConversationProps) => {
  const { dispatch, useAppSelector } = useRedux();
  const errorData = createSelector(
    (state: any) => state.Chats,
    (state) => ({
      getUserConversationsLoading: state.getUserConversationsLoading,
      isMessageForwarded: state.isMessageForwarded,
      selectedConversation: state.selectedConversation,
      messagesHistories: state.messagesHistories,
      messagReadedInconverstaion: state.messagReadedInconverstaion,
    })
  );

  const { getUserConversationsLoading, selectedConversation, messagesHistories, messagReadedInconverstaion } = useAppSelector(errorData);
  const renderGroupedDateMessages = () => {
    // Sắp xếp tin nhắn theo createdAt
    const sortedMessages = Object.values(messagesHistories?.items).sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  
    // Nhóm tin nhắn theo ngày
    const groupedMessagesObject: { [key: string]: any[] } = sortedMessages.reduce((groups: { [key: string]: any[] }, message: any) => {
      const date = moment(message.createdAt).format("DD/MM/YYYY");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  
    return Object.keys(groupedMessagesObject).reverse().map((date) => {
      return (
        <div key={date}>
          <div className="chat-day-title">
            <span className="title">
              {
                date === moment().format('DD/MM/YYYY') ?
                  'Hôm nay' :
                  date === moment().subtract(1, 'days').format('DD/MM/YYYY') ?
                    'Hôm qua' :
                    date
              }
            </span>
          </div>
          {
            renderGroupedTimeMessages(groupedMessagesObject[date])
          }
        </div>
      );
    });
  };
  
  const renderGroupedTimeMessages = (messageList: any) => {
    // const seenMessages = messagesHistories?.items?.filter((message: any) => message.seen);
    // const lastSeenMessage = seenMessages?.[seenMessages?.length - 1] || null;

    const groupedMessages = messageList?.length > 0 && messageList.reduce((groups: any, message: any) => {
      const date = new Date(moment(message.createdAt).valueOf());
      const hour = date.getHours();
      if (!groups[hour]) {
        groups[hour] = [];
      }
      groups[hour].push(message);
      return groups;
    }, {});
    const groupedMessagesArray = Object.entries(groupedMessages).map(([hour, messages]) => ({
      hour,
      messages
    }));
    return (
      groupedMessagesArray.map((group: any, index: number) => {
        return (
          <div
            key={group?.hour}
            style={{
              marginBottom: index == groupedMessagesArray?.length - 1 ? 0 : 60,
            }}
          >
            <ul className="list-unstyled chat-conversation-list">
              {group.messages.map((message: any, key: number) => {
                const isFromMe = message?.from?.id == message?.channelId;
                return (
                  <Message
                    messagReadedInconverstaion={messagReadedInconverstaion}
                    message={message}
                    key={message?.messageId}
                    selectedConversation={selectedConversation}
                    isFromMe={isFromMe}
                  />
                );
              })}
            </ul>
            {
              group.messages[group.messages?.length - 1]?.from?.id !== selectedConversation?.conversationId ? (
                group.messages[group.messages?.length - 1]?.createdBy?.name ? (
                  <div
                    style={{
                      justifyContent: 'flex-end',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <div style={{ fontSize: 11, color: '#495057bf' }}>
                      Người gửi: <span style={{ fontSize: 11, color: '#1A1A1A' }}>{group.messages[group.messages?.length - 1]?.createdBy?.name}</span>
                    </div>
                  </div>
                ) : null
              ) : (
                group.messages[group.messages?.length - 1]?.from?.name ? (
                  <div
                    style={{
                      justifyContent: 'flex-start',
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: 44,
                    }}>
                    <div style={{ fontSize: 11, color: '#495057bf' }}>
                      Người gửi: <span style={{ fontSize: 11, color: '#1A1A1A' }}>{group.messages[group.messages?.length - 1]?.from?.name}</span>
                    </div>
                  </div>
                ) : null
              )
            }
          </div>
        );
      })
    );
  };

  const fetchMoreData = () => {
    if (messagesHistories?.total === Object.keys(messagesHistories?.items).length) {
      return;
    }
    console.log("vao day");
    setTimeout(() => {
      dispatch(getMessagesHistories({
        id: selectedConversation?.conversationId,
        page: messagesHistories?.page + 1,
        limit: 20,
      }));
    }, 300);
  };

  return (
    <div
      className="chat-conversation p-3 p-lg-4 positin-relative"
      id="scrollableDiv"
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      <div style={{ marginTop: 90, paddingTop: 10, marginBottom: 0, paddingLeft: 0, position: 'relative' }}
      >
        {getUserConversationsLoading &&
          <Loader />}
        {
          Object.keys(messagesHistories?.items).length > 0 ? (
            <InfiniteScroll
              dataLength={Object.keys(messagesHistories?.items).length}
              next={fetchMoreData}
              hasMore={messagesHistories?.total > Object.keys(messagesHistories?.items).length}
              loader={Object.keys(messagesHistories?.items).length > 0 &&
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 30
                }}>
                  <Spin indicator={<LoadingOutlined spin />} />
                </div>
              }
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
                overflowY: "auto",
                overflowX: "hidden",
              }}
              inverse={true}
              scrollableTarget="scrollableDiv"
            >
              {renderGroupedDateMessages()}
            </InfiniteScroll>
          ) : null
        }
      </div>
    </div>
  );
};

export default MessagesHistories;
