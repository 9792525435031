import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRedux, useProfile } from "../../hooks/index";
import { createSelector } from "reselect";

// actions
import {
    addTagGroupSuccess,
    changeGroupInfo,
    changePageInfo,
} from "../../redux/actions";

// router
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

// components
import { Tooltip, Dropdown, theme, Modal } from 'antd';
import {
    LogoutOutlined,
    TableOutlined,
    AppstoreAddOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import ModalGroup from "../../pages/Dashboard/ModalGroup/index";
import AddTagModal from "../../pages/Tags/AddTagModal/index";

// api
import {
    getGroupDetail,
    getPageSelected,
} from "../../api/index";
import ModalSetting from "./ModalSetting";

const { useToken } = theme;

const menuTabs = [
    {
        id: '1',
        name: 'Hội thoại',
        route: '/conversation-list'
    },
    {
        id: '2',
        name: 'Đơn hàng',
        route: '/ordered-list'
    },
    {
        id: '3',
        name: 'Thống kê',
        route: '/statistic/overview'
    },
];

interface HeaderProps {
    className?: string;
    infoSetting: any;
}

const Header = ({ className, infoSetting }: HeaderProps) => {
    const navigate = useNavigate();
    const { dispatch, useAppSelector } = useRedux();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const { userProfile } = useProfile();
    const groupId = searchParams.get('groupId');
    const channelSelected = searchParams.get('channelId');
    const { token } = useToken();

    const contentStyle: React.CSSProperties = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
    };

    const data = createSelector(
      (state : any) => state.Chats,
      (state) => ({
        groupInfo: state.groupInfo,
        pageInfo: state.pageInfo,
      })
    );
    // Inside your component
    const { groupInfo, pageInfo } = useAppSelector(data);
    
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const [showModalGroup, setShowModalGroup] = useState<boolean>(false);
    const [selectedTab, setActiveTab] = useState<string>('1');
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [isOpenModalSetting, setIsOpenModalSetting] = useState<boolean>(false);

    useEffect(() => {
        const path = location.pathname;
        setActiveTab(menuTabs.find((tab) => path.includes(tab.route.split('/')[1]))?.id || '4');
    }, [location]);

    const onChangeTab = (id: string, route: string) => {
        if (id && route) {
            setActiveTab(id);
            navigate(`${route}?${groupId ? `groupId=${groupId}` : channelSelected ? `channelId=${channelSelected}` : ''}`);
        } else {
            setIsOpenModal(true);
        }
    };

    // Call đến Api get chi tiết group để get list channel theo group -> hiển thị ds channel theo group
    useEffect(() => {
        if (groupId) {
            getGroupData(groupId);
        } else if (channelSelected && channelSelected !== pageInfo?.channelSelected) {
            getPagesGroupData({
                channelIds: channelSelected,
            });
        }
    }, [groupId, channelSelected]);

    const getGroupData = async (groupId: string) => {
        try {
            const response: any = await getGroupDetail(groupId);
            if (response?.data && response?.success) {
                dispatch(changeGroupInfo(response?.data));
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const getPagesGroupData = async (data: any) => {
        try {
            const response: any = await getPageSelected({ channelIds: data?.channelIds, isAll: true });
            if (response?.data?.items?.length > 0) {
                dispatch(changePageInfo(response?.data?.items[0]));
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    return (
        <div className={className}>
            <div className="d-flex w-100 justify-content-between align-items-center">
                <div
                    className="d-flex align-items-center cursor-pointer"
                    style={{
                        minWidth: 840,
                        width: 'calc(100% - 245px)',
                        overflowX: 'auto',
                        overflowY: 'hidden',
                    }}
                >
                    <div
                        className="d-flex align-items-center cursor-pointer"
                        onClick={() => {
                            navigate("/dashboard");
                        }}
                        style={{
                            maxWidth: 300,
                            marginRight: 90,
                        }}
                    >
                        <div className="logo">
                            <img src={infoSetting?.logoUrl} alt="" className="img-logo" />
                        </div>
                        <div style={{
                            marginLeft: 8,
                            color: 'white',
                            fontSize: 20,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}>{infoSetting?.title}</div>
                    </div>
                    {
                        (groupId || channelSelected) && menuTabs.concat(
                            groupId ? [{
                                id: '4',
                                name: 'Thêm nhãn hội thoại',
                                route: ''
                            }] : [{
                                id: '4',
                                name: 'Cài đặt',
                                route: '/setting/tags'
                            }]
                        ).map((tab, index) => (
                            <div
                                key={index}
                                className={`tab-item ${tab.id === selectedTab ? 'tab-selected' : ''}`}
                                onClick={() => onChangeTab(tab.id, tab.route)}
                            >
                                {tab.name}
                            </div>
                        ))
                    }
                </div>
                <div>
                    <Dropdown
                        open={isOpenDropdown}
                        placement="bottom"
                        onOpenChange={(open) => setIsOpenDropdown(open)}
                        dropdownRender={() => (
                            <div style={{ ...contentStyle, padding: 8 }}>
                                {
                                    (groupId || channelSelected) ? (
                                        <>
                                            <div className="page-list">
                                                {
                                                    groupId ? (
                                                        groupInfo && groupInfo?.channels?.map((item: any, index: number) => (
                                                            <div
                                                                key={index}
                                                                className="item-option"
                                                                style={{
                                                                    maxWidth: 400,
                                                                }}
                                                                onClick={() => {
                                                                    setIsOpenDropdown(false);
                                                                    navigate(`/conversation-list?channelId=${item?.id}`);
                                                                }}
                                                            >
                                                                <img
                                                                    src={item?.picture}
                                                                    alt=""
                                                                    style={{ width: 32, height: 32, marginRight: 8, borderRadius: '50%' }}
                                                                />
                                                                <span style={{
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis'
                                                                }}>{item?.name}</span>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div
                                                            className="item-option"
                                                            style={{
                                                                maxWidth: 400,
                                                            }}
                                                            onClick={() => {
                                                                setIsOpenDropdown(false);
                                                                navigate(`/conversation-list?channelId=${pageInfo?.id}`);
                                                            }}
                                                        >
                                                            <img
                                                                src={pageInfo?.picture}
                                                                alt=""
                                                                style={{ width: 32, height: 32, marginRight: 8, borderRadius: '50%' }}
                                                            />
                                                            <span style={{
                                                                overflow: 'hidden',
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis'
                                                            }}>{pageInfo?.name}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="border-team" />
                                            <div
                                                className="item-option"
                                                onClick={() => {
                                                    setIsOpenDropdown(false);
                                                    navigate(`/dashboard`);
                                                }}
                                            >
                                                <TableOutlined style={{
                                                    marginRight: 12
                                                }} />
                                                <span>Bảng điều khiển</span>
                                            </div>
                                            <div
                                                className="item-option"
                                                onClick={() => {
                                                    setIsOpenDropdown(false);
                                                    setShowModalGroup(true);
                                                }}
                                            >
                                                <AppstoreAddOutlined style={{
                                                    marginRight: 12
                                                }} />
                                                <span>Chế độ gộp trang</span>
                                            </div>
                                            <div className="border-team" style={{ marginTop: 8 }} />
                                        </>
                                    ) : null
                                }
                                <div
                                    className="item-option"
                                    onClick={() => {
                                        setIsOpenModalSetting(true);
                                    }}
                                >
                                    <SettingOutlined style={{
                                        marginRight: 12
                                    }} />
                                    <span>Cài đặt</span>
                                </div>
                                <div className="border-team" style={{ marginTop: 8 }} />
                                
                                <Tooltip placement="right" title='Đăng xuất'>
                                    <div
                                        className="item-option"
                                        style={{
                                            justifyContent: 'space-between'
                                        }}
                                        onClick={() => {
                                            navigate('/logout')
                                        }}
                                    >
                                        <div className="d-flex align-items-center">
                                            <img src={userProfile?.avatar} alt="" style={{ width: 40, height: 40, borderRadius: '50%' }} />
                                            <div style={{ marginLeft: 8 }}>{userProfile?.name}</div>
                                        </div>
                                        <LogoutOutlined style={{
                                            marginLeft: 16
                                        }} />
                                    </div>
                                </Tooltip>
                                <div style={{
                                    fontSize: 12,
                                    padding: '4px 24px 0',
                                }}>Version: 1.1.2</div>
                            </div>
                        )}
                    >
                        {
                            (groupId || channelSelected) ? (
                                <div className="user-header">
                                    {
                                        groupId ? (
                                            <div style={{
                                                display: 'flex',
                                                position: 'relative',
                                            }}>
                                                {
                                                    groupInfo && groupInfo?.channels?.slice(0, 3).map((page: any, index: number) => (
                                                        <img
                                                            key={index}
                                                            src={page?.picture}
                                                            alt=""
                                                            style={{
                                                                width: 36,
                                                                height: 36,
                                                                borderRadius: '50%',
                                                                border: '2px solid white',
                                                                marginLeft: index == 0 ? 0 : -16,
                                                            }}
                                                        />
                                                    ))
                                                }
                                                {
                                                    groupInfo && groupInfo?.channels?.length > 3 ? (
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 0,
                                                            width: 36,
                                                            height: 36,
                                                            borderRadius: '50%',
                                                            backgroundColor: '#0000002a',
                                                        }}>
                                                            <span style={{ color: 'white', fontSize: 13 }}>+{groupInfo && groupInfo?.channels?.length - 3}</span>
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                        ) : (
                                            <img
                                                src={pageInfo?.picture}
                                                alt=""
                                                className="avatar"
                                            />
                                        )
                                    }
                                    <span style={{
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        color: 'white',
                                        marginLeft: 8
                                    }}>
                                        {
                                            groupId ? groupInfo?.name : pageInfo?.name
                                        }
                                    </span>
                                </div>
                            ) : (
                                <div className="user-header">
                                    <img
                                        src={userProfile?.avatar}
                                        alt=""
                                        style={{
                                            width: 36,
                                            height: 36,
                                            borderRadius: '50%',
                                            border: '2px solid white',
                                        }}
                                    />
                                    <div style={{ marginLeft: 8, color: 'white' }}>{userProfile?.name}</div>
                                </div>
                            )
                        }
                    </Dropdown>
                </div>
            </div>

            
            {showModalGroup && (
                <ModalGroup
                    isOpen={showModalGroup}
                    onClose={() => setShowModalGroup(false)}
                />
            )}
            {isOpenModal && (
                <AddTagModal
                    isOpen={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                    onSuccess={() => {
                        setIsOpenModal(false);
                        dispatch(addTagGroupSuccess(true));
                    }}
                    channelSelected={groupId ? groupInfo?.channelIds.join(',') : pageInfo?.id}
                    tagLength={0}
                    tagSelected={null}
                    addMultiple
                />
            )}
            <Modal
                title="Cài đặt"
                open={isOpenModalSetting}
                onCancel={() => {
                    setIsOpenModalSetting(false);
                }}
                footer={null}
            >
                <ModalSetting/>
            </Modal>
        </div>
    );
};

export default styled(Header)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(var(--bs-primary-rgb), 1);
    height: 56px;
    padding: 0 140px;
    .avatar {
      width: 36px;
      height: 36px;
      border: 2px solid white;
      border-radius: 50%;
    }
    .logo {
        background-color: #1d293940;
        border-radius: 8px;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .img-logo {
        width: 41px;
        height: 41px;
    }
    .tab-item {
        padding: 8px 12px;
        border-radius: 8px;
        color: white;
        font-weight: 500;
        margin-right: 8px;
        font-size: 14px;
        &:hover {
          background-color: #1d293940;
        }
    }
    .tab-selected {
      background-color: #1d2939a6 !important;
    }
    .user-header {
        display: flex;
        align-items: center;
        cursor: pointer;
        max-width: 245px;
        justify-content: flex-end;
        border-radius: 8px;
        padding: 4px 12px;
        &:hover {
          background-color: #1d293940;
        }
    }
`