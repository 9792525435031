import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { APIClient } from '../api/apiCore';

const api = new APIClient();

const { Option } = Select;

interface OptionType {
    key: any;
    value: string;
}

interface SelectBaseProps extends React.ComponentProps<typeof Select> {
    optionRenderer?: (option: OptionType) => React.ReactNode;
    endpoint: string;
    onChange?: any;
}

const SelectBase: React.FC<SelectBaseProps> = (props) => {
    const { optionRenderer, onChange, endpoint, ...restProps } = props;
    const [options, setOptions] = useState<OptionType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(1);
    const [q, setQ] = useState<string>('');

    const fetchOptions = async (query: string = '') => {
        setPage(1);
        setLoading(true);
        try {
            const response = await api.get(props.endpoint, {
                params: { q: query, limit: 20, page: 1 },
            });
            const data = response.data?.items ? response.data?.items?.map((item: any) => ({
                ...item,
                key: item.id,
                value: item.name,
            })) : [];
            setOptions(data);
        } catch (error) {
            console.error('Error fetching options:', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchMoreOptions = async () => {
        setLoading(true);
        try {
            const response = await api.get(props.endpoint, {
                params: { q: '', limit: 20, page: page + 1  },
            });
            setPage(page + 1);
            const data = response.data?.items ? response.data?.items?.map((item: any) => ({
                ...item,
                key: item.id,
                value: item.name,
            })) : [];
            setOptions(prevOptions => [...prevOptions, ...data]);
        } catch (error) {
            console.error('Error fetching more options:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOptions();
    }, []);

    const debounce = (func: (...args: any[]) => void, wait: number) => {
        let timeout: NodeJS.Timeout;
        return (...args: any[]) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    const handleSearch = debounce((value: string) => {
        setQ(value);
        setLoading(true);
        fetchOptions(value).finally(() => setLoading(false));
    }, 300);

    const handleSelect = (value: any, item: any) => {
        if(q) {
            fetchOptions();
            setQ('');
        }
    };

    const handleChange = (values: any, items: any) => {
        onChange && onChange(values, items);
    };

   
    const handlePopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const { target } = e;
        const element = target as HTMLDivElement;
        if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
            fetchMoreOptions();
        }
    };
    return (
        <Select
            {...restProps}
            onPopupScroll={handlePopupScroll}
            showSearch
            loading={loading}
            onSearch={handleSearch}
            onSelect={handleSelect}
            onChange={handleChange}
            filterOption={false}
        >
            {options.map(option => (
                <Option key={option.key} value={option.value} option={option}>
                    {optionRenderer ? optionRenderer(option) : option.value}
                </Option>
            ))}
        </Select>
    );
};

SelectBase.defaultProps = {
    optionRenderer: (option: OptionType) => option.value,
};

export default SelectBase;