import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// api
import {
  getPageListConnected,
  cancelPage
} from "../../../api/index";

// component
import { Tooltip } from 'antd';
import { MinusCircleTwoTone } from '@ant-design/icons';
import iconFb from "../../../assets/images/users/ic_fb.png";
import ControlTable from "../ControlTable/index";
import AppList from "../AppList/index";

// styles
import styled from "styled-components";
import { hexToRgba } from "../../../utils/color";

interface IndexProps {
  className?: string;
}

const Index = (props: IndexProps) => {
  const { className } = props;
  const navigate = useNavigate();
  const [pageList, setPageList] = useState<any>(null);
  const [valueSearch, setValueSearch] = useState<string>('');
  const [totalPage, setTotalPage] = useState<number>(0);

  useEffect(() => {
    // fetch data
    if (pageList === null) {
      getPageListData({ page: 1, limit: 50, valueSearch: valueSearch });
    }
  }, []);

  const handleScrollToBottom = useCallback(() => {
    const element = document.getElementById("page-list");
    if (element) {
      const { scrollTop, clientHeight, scrollHeight } = element;
      if ((scrollTop + clientHeight + 1 >= scrollHeight) && pageList?.total > pageList?.items?.length) {
        getPageListData({
          page: pageList?.page + 1,
          limit: 50,
          valueSearch: valueSearch
        });
      }
    }
  }, [pageList]);

  useEffect(() => {
    const element = document.getElementById("page-list");
    if (element) {
      element.addEventListener("scroll", handleScrollToBottom);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScrollToBottom);
      }
    };
  }, [handleScrollToBottom]);

  const getPageListData = async (data: any) => {
    try {
      const response: any = await getPageListConnected({ page: data?.page, limit: data?.limit, q: data?.valueSearch });
      if (response?.data?.items) {
        const arr = pageList?.items && data.page !== 1 ? [...pageList?.items, ...response.data.items] : response.data.items;
        setPageList({
          total: response.data.total,
          page: data.page,
          items: arr,
        });
        if (!data?.valueSearch) {
          setTotalPage(response.data.total);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const onCancel = async (id: string) => {
    try {
      const response: any = await cancelPage(id);
      if (response?.data && response?.success) {
        getPageListData({ page: 1, limit: 50, valueSearch: valueSearch });
        toast.success('Xóa page thành công!');
      } else {
        toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSearch = async (value: string) => {
    setValueSearch(value);
    getPageListData({ page: 1, limit: 50, valueSearch: value });
  }

  return (
    <div className={className} style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '80%' }}>
        <ControlTable
          onGet={() => getPageListData({ page: 1, limit: 50, valueSearch: valueSearch })}
          onSearch={(value) => onSearch(value)}
          showGroup={totalPage > 1}
        />
        <AppList
          pageList={pageList?.items}
          total={totalPage}
        />
        <div
          className="bg-white"
          id="page-list"
          style={{
            borderRadius: 8,
            marginTop: 16,
            height: 'calc(100vh - 350px)',
            overflow: 'auto',
            padding: '16px 0 16px 16px'
          }}
        >
          {
            pageList?.items.length > 0 ? (
              <ul className="list-inline user-chat-nav mb-0">
                {
                  pageList?.items?.map((item: any, index: number) => {
                    if (!item.name) return null;
                    return (
                      <li
                        key={index}
                        className="list-inline-item d-inline-block me-3 ms-0 mb-3"
                        style={{
                          width: 'calc((100% - 48px) / 3)',
                        }}
                      >
                        <div className="channel-item">
                          <div
                            className="channel-content"
                            onClick={() => {
                              navigate(`/conversation-list?channelId=${item?.id}`);
                            }}
                          >
                            <img
                              src={item?.picture}
                              alt=""
                              style={{ width: 64, height: 64, marginRight: 8, borderRadius: 5 }}
                            />
                            <div style={{display: 'grid'}}>
                              <Tooltip title={item?.name}>
                                <div
                                  style={{
                                    fontWeight: 'bold',
                                    marginBottom: 4,
                                    marginRight: 20,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis'
                                  }}>
                                    {item?.name}
                                </div>
                              </Tooltip>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis'
                                }}
                              >
                                <img
                                  src={iconFb}
                                  alt=""
                                  style={{ width: 14, height: 14, marginRight: 4 }}
                                />
                                <span
                                  style={{
                                    fontSize: 12,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {item?.id}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className="cancel-channel"
                            onClick={() => onCancel(item?.id)}
                          >
                            <Tooltip title={'Hủy kích hoạt trang'}>
                              <MinusCircleTwoTone twoToneColor="red" style={{ fontSize: 18 }} />
                            </Tooltip>
                          </div>
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
            ) : null
          }
        </div>
      </div>
    </div>
  );
};

export default styled(Index)`
  .channel-item {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #f0f0f0;
    padding: 10px;
    border-radius: 5px;
  }
  .channel-item:hover {
    background-color: ${hexToRgba(localStorage.getItem('colorTheme') || '#2f54eb', 0.3)};
    .cancel-channel {
      display: block;
    }
  }
  .cancel-channel {
    display: none;
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .channel-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
`
