import { PaperClipOutlined } from "@ant-design/icons";
import { Button, Modal, Radio, RadioChangeEvent, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { RowTable } from "../components/RowTable";

interface SortQuickRepliesProps {
  isOpen: boolean;
  quickRepliesList: any;
  topicList: any;
  onClose: () => void;
  onSuccess: (newList: any) => void;
}

const SortQuickReplies = ({
  isOpen,
  quickRepliesList,
  topicList,
  onClose,
  onSuccess,
}: SortQuickRepliesProps) => {
  const [sortValue, setSortValue] = useState<string>("ASC");
  const [listQuickRepliesSort, setQuickRepliesList] = useState<any>([...quickRepliesList]);

  const columnsQuickReplies: ColumnsType<any> = [
    {
      title: "STT",
      width: 100,
      render: record => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>{record.index}</span>
        </div>
      ),
    },
    {
      title: "Kí tự tắt",
      width: 300,
      render: record => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>{record.shortcut}</span>
        </div>
      ),
    },
    {
      title: "Chủ đề",
      width: 200,
      render: record => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 100,
            borderRadius: 10,
            color: "white",
            backgroundColor: topicList?.items?.find(
              (topic: any) => topic.id === record?.topicId,
            )?.color,
          }}
        >
          <span>
            {
              topicList?.items?.find(
                (topic: any) => topic.id === record?.topicId,
              )?.name
            }
          </span>
        </div>
      ),
    },
    {
      title: "Tin nhắn",
      render: record =>
        record?.messages?.map((message: any, index: number) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            {message?.photos?.length > 0 && (
              <div
                style={{
                  position: "relative",
                  width: 24,
                  height: 24,
                  marginRight: 8,
                  borderRadius: 6,
                }}
              >
                <img
                  src={message?.photos[0]?.location}
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: 6,
                  }}
                />
                {message?.photos?.length > 1 && (
                  <Tooltip
                    title={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {message?.photos?.map((photo: any, index: number) => (
                          <img
                            key={index}
                            src={photo?.location}
                            style={{
                              width: 24,
                              height: 24,
                              borderRadius: 6,
                              marginLeft: index === 0 ? 0 : 8,
                            }}
                          />
                        ))}
                      </div>
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: 24,
                        height: 24,
                        borderRadius: 6,
                        backgroundColor: "#0000004d",
                      }}
                    >
                      <span style={{ color: "white" }}>
                        +{message?.photos?.length - 1}
                      </span>
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
            {message?.files?.length > 0 && (
              <Tooltip
                title={message?.files?.map((file: any, index: number) => (
                  <div key={index}>{file?.originalname}</div>
                ))}
              >
                <div
                  style={{
                    width: 14,
                    height: 14,
                    position: "relative",
                    marginRight: 16,
                  }}
                >
                  <PaperClipOutlined style={{ fontSize: 14 }} />
                  <div
                    style={{
                      fontSize: message?.files?.length > 99 ? 7 : 10,
                      position: "absolute",
                      top: -6,
                      right: -9,
                      width: 16,
                      height: 16,
                      backgroundColor: "red",
                      borderRadius: "50%",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "bold",
                      border: "1px solid white",
                    }}
                  >
                    {message?.files?.length > 99
                      ? "99+"
                      : message?.files?.length}
                  </div>
                </div>
              </Tooltip>
            )}
            <Tooltip title={message?.message}>
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {message?.message}
              </span>
            </Tooltip>
          </div>
        )),
    },
  ];

  const sortListASC = (isASC: boolean) => {
    // sort topicList by sequence
    const sortTopicList = topicList?.items?.sort(
      (a: any, b: any) => a.sequence - b.sequence,
    );
    // sort quickRepliesList by sequence of sortTopicList if quickReplies items is no id, move it to the top of list
    const sortedQuickReplies = listQuickRepliesSort.sort((a: any, b: any) => {
      if (!a.topicId && b.topicId) return isASC ? -1 : 1;
      if (a.topicId && !b.topicId) return isASC ? 1 : -1;

      const topicA = sortTopicList.find((topic: any) => topic.id === a.topicId);
      const topicB = sortTopicList.find((topic: any) => topic.id === b.topicId);

      if (!topicA || !topicB) return 0;
      if (isASC) {
        return topicA.sequence - topicB.sequence;
      }
      return topicB.sequence - topicA.sequence;
    });
    const updatedQuickReplies = sortedQuickReplies.map(
      (item: any, index: any) => ({
        ...item,
        sequence: index + 1,
      }),
    );

    setQuickRepliesList(updatedQuickReplies);
  };

  const onChangeMode = (e: RadioChangeEvent) => {
    setSortValue(e.target.value);
    sortListASC(e.target.value === "ASC");
  };

  useEffect(() => {
    sortListASC(true);
  }, []);

  return (
    <Modal open={isOpen} footer={null} onCancel={onClose} width={1000}>
      <div className="">
        <div style={{ fontSize: 16, fontWeight: 600 }}>
          Sắp xếp mẫu câu trả lời nhanh
        </div>
        <div
          style={{
            fontSize: 12,
            fontWeight: 400,
            marginBottom: 12,
            color: "#667085",
          }}
        >
          Sắp xếp mẫu câu trả lời nhanh theo chủ đề
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: "5px 8px",
              marginRight: 10,
            }}
          >
            <Radio
              onChange={onChangeMode}
              value={"ASC"}
              checked={sortValue === "ASC"}
            >
              <span style={{ fontSize: 16, fontWeight: "500" }}>
                Từ trên xuống dưới
              </span>
            </Radio>
          </div>
          <div
            style={{
              padding: "5px 8px",
            }}
          >
            <Radio
              onChange={onChangeMode}
              value={"DESC"}
              checked={sortValue === "DESC"}
            >
              <span style={{ fontSize: 16, fontWeight: "500" }}>
                Từ dưới lên trên
              </span>
            </Radio>
          </div>
        </div>
        <div
          className="bg-white"
          id="quickReplies-list"
          style={{
            borderRadius: 8,
            marginTop: 16,
            zIndex: 1,
            height: "calc(50vh - 230px)",
            overflowY: "auto",
            overflowX: "hidden",
            border: "1px solid #d0d0d0",
            position: "relative",
          }}
        >
          <Table
            rowKey="key"
            rowClassName="btn-tag"
            pagination={false}
            components={{ body: { row: RowTable } }}
            columns={columnsQuickReplies}
            dataSource={(listQuickRepliesSort || []).map(
              (tag: any, index: number) => ({
                key: tag.id,
                index: index + 1,
                ...tag,
              }),
            )}
          />
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 16,
            }}
          >
            <Button
              style={{
                width: 100,
                height: 40,
                borderRadius: 8,
                marginRight: 16,
              }}
              onClick={onClose}
            >
              Đóng lại
            </Button>
            <Button
              style={{
                width: 100,
                height: 40,
                borderRadius: 8,
                backgroundColor: "#3c8c3f",
                color: "white",
              }}
              onClick={() => {
                onSuccess(listQuickRepliesSort);
              }}
            >
              Áp dụng
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default SortQuickReplies;
