import React, { useEffect, useState } from "react";
import styled from "styled-components";

// components
import { Dropdown, Tooltip, Badge, Popover } from "antd";
import {
  CalendarOutlined,
  WechatOutlined
} from "@ant-design/icons";
import { createSelector } from "reselect";
import { useRedux } from "../../hooks";
import { CloseCircleOutlined } from '@ant-design/icons';
import { changeConversationFilterState } from "../../redux/chats/actions";
import { ConversationFilterState } from "../../redux/chats/types";
import FilterDateRanger from "./FilterDateRanger";
import IconCalendar from "../../assets/images/calendar.svg";
import IconComment from "../../assets/images/comment.svg";
import IconMessenger from "../../assets/images/messenger.svg";
import IconCloseCalendar from "../../assets/images/close_calendar.svg";
const menuTabs = [
  {
    id: '1',
    name: 'Tất cả hội thoại',
    icon: <WechatOutlined style={{ fontSize: 24, color: 'white' }} />
  },
  {
    id: '2',
    name: 'Lọc theo thời gian',
    icon: <img className="icon_calendar" src={IconCalendar} />
  },
  {
    id: '3',
    name: 'Lọc bình luận',
    icon: <img className="icon_calendar" src={IconComment} />
  },
  {
    id: '4',
    name: 'Lọc tin nhắn',
    icon: <img className="icon_calendar" src={IconMessenger} />
  },
];

interface SideMenuProps {
  className?: string;
  countConversation: number;
}

const SideMenu = ({ className, countConversation }: SideMenuProps) => {
  const [selectedTabs, setActiveTab] = useState<string[]>(['1']);
  const [showDropdownCalendar, setShowDropdownCalendar] = useState<boolean>(false);

  const [showFormTime, setShowFormTime] = useState<boolean>(false);

  const hide = () => {
    setShowFormTime(false);
  };


  const { dispatch, useAppSelector } = useRedux();
  const state = createSelector(
    (props: any) => props.Chats,
    (props) => ({
      conversationFilterState: props.conversationFilterState,
    })
  );

  const { conversationFilterState } = useAppSelector(state);

  const onChangeTab = (id: string, filterState?: ConversationFilterState) => {
    if (id === '1') {
      setActiveTab(['1']);
      dispatch(changeConversationFilterState({
        ...conversationFilterState,
        startTime: undefined,
        endTime: undefined,
        type: ''
      }));
    } else {
      if (selectedTabs.includes(id) && !filterState) {
        if (selectedTabs.filter((element) => element !== id && element !== '1')?.length === 0) {
          setActiveTab(['1']);
          dispatch(changeConversationFilterState({
            ...conversationFilterState,
            startTime: undefined,
            endTime: undefined,
            type: ''
          }));
        } else {
          setActiveTab(selectedTabs.filter((element) => element !== id && element !== '1'));
          switch(id) {
            case '2':
              dispatch(changeConversationFilterState({
                ...conversationFilterState,
                startTime: undefined,
                endTime: undefined,
              }));
              break;
            case '3':
              dispatch(changeConversationFilterState({
                ...conversationFilterState,
                type: conversationFilterState?.type?.replace('COMMENT', '')
              }));
              break;
            case '4':
              dispatch(changeConversationFilterState({
                ...conversationFilterState,
                type: conversationFilterState?.type?.replace('INBOX', '')
              }));
              break;
          }
        }
      } else {
        setActiveTab(selectedTabs.filter((element) => element !== '1').concat([id]));
        switch(id) {
          case '2':
            dispatch(changeConversationFilterState({
              ...filterState,
              type: conversationFilterState?.type,
            }));
            break;
          case '3':
            dispatch(changeConversationFilterState({
              ...conversationFilterState,
              type: conversationFilterState?.type + 'COMMENT'
            }));
            break;
          case '4':
            dispatch(changeConversationFilterState({
              ...conversationFilterState,
              type: conversationFilterState?.type + 'INBOX'
            }));
            break;
        }
      }
    }
  };

  const handleVisibleChange = (tab: any) => {
    setShowFormTime(!showFormTime)
  };

  return (
    <div className={className}>
      <div className="tab-menu">
        {
          menuTabs.map((tab, index) => {
            if (tab.id === '2') {
              return (
                <Tooltip key={index} placement="right" title={tab.name}>
                  <Popover placement="rightTop" title={null}
                    open={showFormTime}
                    onOpenChange={handleVisibleChange}
                    content={
                      <FilterDateRanger
                        filterState={conversationFilterState}
                        onChangeFilterState={(filterState) => {
                          onChangeTab(tab.id, filterState)
                        }}
                        setShowDropdownCalendar={setShowDropdownCalendar}
                        setShowFormTime={setShowFormTime}
                      />
                    } trigger="click">
                    <div className={`tab-item ${selectedTabs.includes(tab.id) ? 'tab-selected' : ''}`}>
                      {conversationFilterState?.startTime === undefined ? tab.icon :
                        <Badge offset={[1, 5]}
                          count={
                            // eslint-disable-next-line jsx-a11y/alt-text
                            <img className="icon_close_calendar" width={17} src={IconCloseCalendar}
                              onClick={() => {
                                setShowFormTime(false)
                                onChangeTab(tab.id)
                              }}
                            />
                          }
                        >
                          <img className="icon_calendar" src={IconCalendar} />
                        </Badge>
                      }
                    </div>
                  </Popover>
                </Tooltip>
              );
            }

            return (
              <Tooltip
                key={index}
                placement="right"
                title={
                  tab.id === '1' && countConversation > 0 ?
                    <span>{tab.name}<br/>(đang hiển thị: {countConversation})</span> :
                    tab.name
                }
              >
                <div
                  className={`tab-item ${selectedTabs.includes(tab.id) ? 'tab-selected' : ''}`}
                  onClick={() => onChangeTab(tab.id)}
                >
                  {tab.icon}
                </div>
              </Tooltip>
            );
          })
        }
      </div>
    </div>
  );
};

export default styled(SideMenu)`
  .tab-menu {
    width: 52px;
    height: calc(100vh - 56px);
    background-color: rgba(var(--bs-primary-rgb), 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9;
    padding: 8px 0;
  }
  .tab-item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 16px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    &:hover {
      background-color: #1d293940;
    }
  }
  .tab-selected {
    background-color: #1d2939a6 !important;
  }
  .icon_calendar {
      -webkit-filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(4473%) hue-rotate(157deg) brightness(106%) contrast(94%);
    filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(4473%) hue-rotate(157deg) brightness(106%) contrast(94%);
    }
    .icon_close_calendar {
      color: #ffffff;
      background-color: #ffffff;
      border-radius: 34px;
      border: 1px solid #343F4D;
      // -webkit-filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(4473%) hue-rotate(157deg) brightness(106%) contrast(94%);
      // filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(4473%) hue-rotate(157deg) brightness(106%) contrast(94%);
    }
`
