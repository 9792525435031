import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import _ from "lodash";

//api
import {
  getPageListConnected,
  getPageSelected,
  syncQuickReply,
} from "../../../api/index";

// component
import type { RadioChangeEvent } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  Tooltip,
  Modal,
  Row,
  Col,
  Dropdown,
  Input,
  Checkbox,
  Table,
  Radio,
  Progress,
  message,
} from "antd";
import iconFb from "../../../assets/images/users/ic_fb.png";
import IconSync from "../../../assets/images/tag/sync.svg";
import IconPageTo from "../../../assets/images/tag/pageTo.svg";
import IconDown from "../../../assets/images/tag/down.svg";
import IconSuccess from "../../../assets/images/tag/success.svg";
import {
  SearchOutlined,
  CloseOutlined,
  CheckOutlined,
  HddFilled,
  PaperClipOutlined,
} from "@ant-design/icons";

// styles
import styled from "styled-components";
import { hexToRgba } from "../../../utils/color";
import { RowTable } from "../components/RowTable";
import SortQuickReplies from "../SortQuickReplyModal";
import { set } from "date-fns";

interface ModalSyncProps {
  allQuickReply: any;
  isOpen: boolean;
  onClose: () => void;
  channelId: string;
  className?: string;
  topicList: any;
}

const Index = ({
  isOpen,
  onClose,
  channelId,
  allQuickReply,
  className,
  topicList,
}: ModalSyncProps) => {
  const [channelSelected, setChannelSelected] = useState<any>(null);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [channelIds, setChannelIds] = useState<any[]>([]);
  const [channelIdsSync, setChannelIdsSync] = useState<any[]>([]);
  const [valueSending, setValueSending] = useState<any>(null);
  const [allPage, setAllPage] = useState<any[]>([]);
  const [pageList, setPageList] = useState<any>(null);
  const [valueSearch, setValueSearch] = useState<string>("");
  const [quickReplyIds, setQuickReplyIds] = useState<any[]>([]);
  const [listQuickReply, setListQuickReply] = useState<any[]>([]);
  const [valueInput, setValueInput] = useState<string>("");
  const [modeValue, setModeValue] = useState<string>("UPDATE_DUPLICATE");

  const [isOpenSortModal, setIsOpenSortModal] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (channelId && channelSelected === null) {
      getDetailChannel({
        channelIds: channelId,
      });
      setListQuickReply(allQuickReply);
    }
  }, [channelSelected]);

  useEffect(() => {
    if (pageList === null) {
      getPageListData({ valueSearch: valueSearch });
    }
  }, []);

  const getPageListData = async (data: any) => {
    try {
      const response: any = await getPageListConnected({
        isAll: true,
        q: data?.valueSearch,
      });
      if (response?.data?.items) {
        const arr = response.data.items.filter(
          (page: any) => page?.id !== channelId,
        );
        setPageList({
          total: response.data.total,
          page: data.page,
          items: arr,
        });
        if (data?.valueSearch?.length == 0 && allPage?.length == 0) {
          setAllPage(arr);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getDetailChannel = async (data: any) => {
    try {
      const response: any = await getPageSelected({
        channelIds: data?.channelIds,
      });
      if (response?.data?.items?.length > 0) {
        setChannelSelected(response?.data?.items?.[0]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onChange = (e: any) => {
    if (e.target.checked) {
      setChannelIds(allPage?.map((item: any) => item.id));
      setValueSending(null);
      setChannelIdsSync([]);
    } else {
      setChannelIds([]);
      setValueSending(null);
      setChannelIdsSync([]);
    }
  };

  const onSelect = (id: string) => {
    const index = channelIds.indexOf(id);
    if (index === -1) {
      setChannelIds([...channelIds, id]);
      setValueSending(null);
      setChannelIdsSync([]);
    } else {
      setChannelIds(channelIds.filter((item: any) => item !== id));
      setValueSending(null);
      setChannelIdsSync([]);
    }
  };

  const searchPages = _.debounce((value: string) => {
    getPageListData({ valueSearch: value });
  }, 300);

  const onChangeQuickReply = (e: any) => {
    if (e.target.checked) {
      setQuickReplyIds(allQuickReply?.map((item: any) => item.id));
      setValueSending(null);
      setChannelIdsSync([]);
    } else {
      setQuickReplyIds([]);
      setValueSending(null);
      setChannelIdsSync([]);
    }
  };

  const onSelectQuickReply = (e: any, id: string) => {
    if (e.target.checked) {
      setQuickReplyIds([...quickReplyIds, id]);
      setValueSending(null);
      setChannelIdsSync([]);
    } else {
      setQuickReplyIds(quickReplyIds.filter((item: any) => item !== id));
      setValueSending(null);
      setChannelIdsSync([]);
    }
  };

  const searchQuickReplys = (e: any) => {
    const value = e.target.value;
    setValueInput(value);
    if (value === "") {
      setListQuickReply(allQuickReply);
    } else {
      setListQuickReply(
        allQuickReply?.filter((item: any) =>
          item?.shortcut.toLowerCase().includes(value.toLowerCase()),
        ),
      );
    }
  };

  const renderDropdown = () => {
    return (
      <div
        className={className}
        style={{
          width: 450,
          borderRadius: 6,
          backgroundColor: "white",
          padding: 6,
        }}
      >
        <div
          style={{
            padding: "8px 9px 5px 8px",
          }}
        >
          <Input
            placeholder="Nhập tên trang để tìm kiếm"
            prefix={<SearchOutlined />}
            onChange={e => {
              setValueSearch(e.target.value);
              searchPages(e.target.value);
            }}
            value={valueSearch}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: 8,
            }}
          >
            <Checkbox
              indeterminate={
                channelIds?.length > 0 && channelIds?.length < allPage?.length
              }
              onChange={onChange}
              checked={channelIds?.length === allPage?.length}
            >
              Chọn tất cả các trang
            </Checkbox>
            {channelIds?.length > 0 ? (
              <div
                style={{
                  display: "inline-block",
                  color: localStorage.getItem("colorTheme") || "#2f54eb",
                  backgroundColor: hexToRgba(
                    localStorage.getItem("colorTheme") || "#2f54eb",
                    0.3,
                  ),
                  borderRadius: 16,
                  padding: "0 8px",
                  fontWeight: "600",
                }}
              >
                Đã chọn {channelIds?.length}
              </div>
            ) : null}
          </div>
        </div>
        <div
          style={{
            overflow: "auto",
            height: 280,
          }}
        >
          {pageList?.items?.map((item: any, index: number) => {
            if (!item.name) return null;
            return (
              <div
                key={index}
                className="option-item"
                style={{
                  marginTop: index !== 0 ? 4 : 0,
                  backgroundColor:
                    channelIds.indexOf(item.id) !== -1
                      ? hexToRgba(
                        localStorage.getItem("colorTheme") || "#2f54eb",
                        0.3,
                      )
                      : "",
                }}
                onClick={() => onSelect(item?.id)}
              >
                <div
                  className="option-content"
                  style={{
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item?.picture}
                      style={{
                        width: 28,
                        height: 28,
                        borderRadius: 4,
                        marginRight: 10,
                      }}
                    />
                    <div style={{ display: "grid" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <img
                          src={iconFb}
                          alt=""
                          style={{ width: 14, height: 14, marginRight: 8 }}
                        />
                        <span
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item?.name}
                        </span>
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                          color: "#667085",
                        }}
                      >
                        {item?.id}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: 16,
                      height: 16,
                    }}
                  >
                    {channelIds.indexOf(item.id) !== -1 ? (
                      <CheckOutlined
                        style={{
                          width: 16,
                          height: 16,
                          color:
                            localStorage.getItem("colorTheme") || "#2f54eb",
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const onOpenSort = () => {
    setIsOpenSortModal(true);
  };

  const columnsQuickReplies: ColumnsType<any> = [
    {
      key: 'sort',
      align: 'center',
      title: (
          <Checkbox
              indeterminate={quickReplyIds?.length > 0 && quickReplyIds?.length < allQuickReply?.length}
              onChange={onChangeQuickReply}
              checked={quickReplyIds?.length === allQuickReply?.length}
          />
      ),
      width: 60,
      render: (record) => (
          <Checkbox
              onChange={(e) => onSelectQuickReply(e, record?.id)}
              checked={quickReplyIds?.includes(record?.id)}
          />
      )
  },
    {
      title: "STT",
      width: 100,
      render: record => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>{record.index}</span>
        </div>
      ),
    },
    {
      title: "Kí tự tắt",
      width: 100,
      render: record => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>{record.shortcut}</span>
        </div>
      ),
    },
    {
      title: () => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Chủ đề</div>
          <Tooltip title="Sắp xếp">
            <div
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={onOpenSort}
              style={{
                padding: "8px",
                display: "inline-block",
                borderRadius: "4px",
                backgroundColor: isHovered ? "#ebebeb" : "transparent",
                transition: "background-color 0.3s",
              }}
            >
              <HddFilled
                style={{
                  color: isHovered ? "#818181" : "#b1b1b1",
                  transition: "color 0.3s",
                }}
              />
            </div>
          </Tooltip>
        </div>
      ),
      width: 200,
      filters: [
        {
          text: (
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                padding: "1px 8px",
                backgroundColor: "#f0f0f0",
                borderRadius: 20,
              }}
            >
              Không chọn chủ đề
            </div>
          ),
          value: "",
        },
        ...(topicList?.items || []).map((topic: any) => ({
          text: (
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                padding: "1px 8px",
                backgroundColor: topic.color,
                color: "#ffffff",
                borderRadius: 20,
              }}
            >
              {topic.name}
            </div>
          ),
          value: topic.id,
        })),
      ],
      onFilter: (value, record) =>
        value === "" ? !record.topicId : record.topicId === value,
      render: record => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 100,
            borderRadius: 10,
            color: "white",
            backgroundColor: topicList?.items?.find(
              (topic: any) => topic.id === record?.topicId,
            )?.color,
          }}
        >
          <span>
            {
              topicList?.items?.find(
                (topic: any) => topic.id === record?.topicId,
              )?.name
            }
          </span>
        </div>
      ),
    },
    {
      title: "Tin nhắn",
      render: record =>
        record?.messages?.map((message: any, index: number) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              width: "400px",
            }}
          >
            {message?.photos?.length > 0 && (
              <div
                style={{
                  position: "relative",
                  width: 24,
                  height: 24,
                  marginRight: 8,
                  borderRadius: 6,
                }}
              >
                <img
                  src={message?.photos[0]?.location}
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: 6,
                  }}
                />
                {message?.photos?.length > 1 && (
                  <Tooltip
                    title={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {message?.photos?.map((photo: any, index: number) => (
                          <img
                            key={index}
                            src={photo?.location}
                            style={{
                              width: 24,
                              height: 24,
                              borderRadius: 6,
                              marginLeft: index === 0 ? 0 : 8,
                            }}
                          />
                        ))}
                      </div>
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: 24,
                        height: 24,
                        borderRadius: 6,
                        backgroundColor: "#0000004d",
                      }}
                    >
                      <span style={{ color: "white" }}>
                        +{message?.photos?.length - 1}
                      </span>
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
            {message?.files?.length > 0 && (
              <Tooltip
                title={message?.files?.map((file: any, index: number) => (
                  <div key={index}>{file?.originalname}</div>
                ))}
              >
                <div
                  style={{
                    width: 14,
                    height: 14,
                    position: "relative",
                    marginRight: 16,
                  }}
                >
                  <PaperClipOutlined style={{ fontSize: 14 }} />
                  <div
                    style={{
                      fontSize: message?.files?.length > 99 ? 7 : 10,
                      position: "absolute",
                      top: -6,
                      right: -9,
                      width: 16,
                      height: 16,
                      backgroundColor: "red",
                      borderRadius: "50%",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "bold",
                      border: "1px solid white",
                    }}
                  >
                    {message?.files?.length > 99
                      ? "99+"
                      : message?.files?.length}
                  </div>
                </div>
              </Tooltip>
            )}
            <Tooltip title={message?.message}>
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {message?.message}
              </span>
            </Tooltip>
          </div>
        )),
    },
  ];

  const onChangeMode = (e: RadioChangeEvent) => {
    setModeValue(e.target.value);
  };

  const onCloseSortModal = () => {
    setIsOpenSortModal(false);
  };

  const onSuccessSort = (list:any) => {
    setListQuickReply(list);
    setIsOpenSortModal(false);
  };

  const onSubmit = async () => {
    try {
      const response: any = await syncQuickReply({
        mode: modeValue,
        sourceChannelId: channelId,
        targetChannelIds: channelIds,
        quickReplyIds: quickReplyIds,
      });
      if (response?.data && response?.success) {
        message.success("Sao chép trả lời nhanh hoàn tất");
        setChannelIdsSync(channelIds);
      } else {
        toast.error(
          response?.data?.message?.error ||
          response?.data?.message ||
          "Có lỗi xảy ra",
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Modal
      className={className}
      open={isOpen}
      onCancel={onClose}
      onOk={() => { }}
      closeIcon={null}
      maskClosable={false}
      footer={null}
      width={1010}
      centered
      styles={{
        content: {
          padding: 0,
        },
      }}
    >
      <Row
        style={{
          alignItems: "center",
          padding: "16px 24px",
          borderBottom: "1px solid #e8e8e8",
        }}
      >
        <Col span={12}>
          <div
            style={{
              fontSize: 18,
              fontWeight: "500",
            }}
          >
            Sao chép trả lời nhanh
          </div>
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CloseOutlined
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={onClose}
          />
        </Col>
      </Row>
      <div
        style={{
          backgroundColor: "#F2F4F7",
          padding: 16,
        }}
      >
        <Row
          style={{
            alignItems: "center",
          }}
        >
          <Col span={11}>
            <div className="channel-item">
              <div className="channel-content">
                <img
                  src={channelSelected?.picture}
                  alt=""
                  style={{
                    width: 60,
                    height: 60,
                    marginRight: 20,
                    borderRadius: "50%",
                    border: "2px solid transparent",
                  }}
                />
                <div style={{ display: "grid" }}>
                  <div
                    style={{
                      fontSize: 12,
                      fontWeight: "500",
                      color: "#667085",
                    }}
                  >
                    Trang nguồn
                  </div>
                  <Tooltip title={channelSelected?.name}>
                    <div
                      style={{
                        fontSize: 16,
                        margin: "3px 0",
                        fontWeight: "500",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {channelSelected?.name}
                    </div>
                  </Tooltip>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={iconFb}
                      alt=""
                      style={{ width: 14, height: 14, marginRight: 4 }}
                    />
                    <span style={{ fontSize: 12, color: "#667085" }}>
                      {channelSelected?.id}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col
            span={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={IconSync} style={{ width: 32, height: 32 }} />
          </Col>
          <Col span={11}>
            <Dropdown
              className={className}
              open={isOpenDropdown}
              trigger={["click"]}
              placement="bottom"
              dropdownRender={() => renderDropdown()}
              onOpenChange={open => setIsOpenDropdown(open)}
            >
              <div className="channel-item">
                <div
                  className="channel-content"
                  style={{
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {channelIds?.length > 0 ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            position: "relative",
                            marginRight: 20,
                          }}
                        >
                          {allPage
                            ?.filter(page => channelIds.includes(page?.id))
                            ?.slice(0, 3)
                            .map((page, index) => (
                              <img
                                key={index}
                                src={page?.picture}
                                alt=""
                                style={{
                                  width: 60,
                                  height: 60,
                                  borderRadius: "50%",
                                  border: "2px solid white",
                                  marginLeft: index == 0 ? 0 : -23,
                                }}
                              />
                            ))}
                          {allPage?.filter(page =>
                            channelIds.includes(page?.id),
                          )?.length > 3 ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                top: 0,
                                right: 0,
                                width: 60,
                                height: 60,
                                borderRadius: "50%",
                                backgroundColor: "#0000002a",
                              }}
                            >
                              <span
                                style={{
                                  color: "white",
                                  fontSize: 16,
                                  fontWeight: "600",
                                }}
                              >
                                {allPage?.filter(page =>
                                  channelIds.includes(page?.id),
                                )?.length - 3}
                                +
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div style={{ display: "grid" }}>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: "500",
                              color: "#667085",
                            }}
                          >
                            Trang đích
                          </div>
                          <Tooltip
                            title={
                              channelIds?.length === 1
                                ? allPage?.find(page =>
                                  channelIds.includes(page?.id),
                                )?.name
                                : `
                                                                    ${allPage?.filter(
                                  page =>
                                    channelIds.includes(
                                      page?.id,
                                    ),
                                )[0]?.name
                                } và ${allPage?.filter(
                                  page =>
                                    channelIds.includes(
                                      page?.id,
                                    ),
                                )
                                  ?.length -
                                1
                                } trang khác
                                                                `
                            }
                          >
                            <div
                              style={{
                                fontSize: 16,
                                margin: "3px 0",
                                fontWeight: "500",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {channelIds?.length === 1
                                ? allPage?.find(page =>
                                  channelIds.includes(page?.id),
                                )?.name
                                : `
                                                                            ${allPage?.filter(
                                  page =>
                                    channelIds.includes(
                                      page?.id,
                                    ),
                                )[0]
                                  ?.name
                                } và ${allPage?.filter(
                                  page =>
                                    channelIds.includes(
                                      page?.id,
                                    ),
                                )
                                  ?.length -
                                1
                                } trang khác
                                                                        `}
                            </div>
                          </Tooltip>
                          <div style={{ height: 19 }} />
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            width: 60,
                            height: 60,
                            marginRight: 20,
                            backgroundColor: "#F2F4F7",
                            borderRadius: "50%",
                            border: "2px solid transparent",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={IconPageTo}
                            style={{ width: 24, height: 24 }}
                          />
                        </div>
                        <div style={{ display: "grid" }}>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: "500",
                              color: "#667085",
                            }}
                          >
                            Trang đích
                          </div>
                          <div
                            style={{
                              fontSize: 16,
                              margin: "3px 0",
                              fontWeight: "500",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Chưa chọn trang
                          </div>
                          <div style={{ height: 19 }} />
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 3,
                    }}
                  >
                    <img src={IconDown} style={{ width: 18, height: 18 }} />
                  </div>
                </div>
              </div>
            </Dropdown>
          </Col>
        </Row>
        <div className="list-tag">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 16,
            }}
          >
            <div className="search-tag">
              <Input
                placeholder="Tìm kiếm"
                prefix={<SearchOutlined />}
                onChange={searchQuickReplys}
                value={valueInput}
              />
            </div>
            {quickReplyIds?.length > 0 ? (
              <div
                style={{
                  display: "inline-block",
                  color: localStorage.getItem("colorTheme") || "#2f54eb",
                  backgroundColor: hexToRgba(
                    localStorage.getItem("colorTheme") || "#2f54eb",
                    0.3,
                  ),
                  borderRadius: 16,
                  padding: "0 8px",
                  fontWeight: "500",
                }}
              >
                Đã chọn {quickReplyIds?.length}
              </div>
            ) : null}
          </div>
          <div
            className="bg-white"
            style={{
              borderRadius: 8,
              marginTop: 16,
              paddingBottom: 4,
              border: "1px solid #d0d0d0",
              width: "100%",
            }}
          >
              <Table
                rowKey="key"
                rowClassName="btn-tag"
                pagination={false}
                components={{ body: { row: RowTable } }}
                columns={columnsQuickReplies}
                dataSource={(listQuickReply || []).map((tag, index) => ({
                  key: tag.id,
                  index: index + 1,
                  ...tag,
                }))}
              />
          </div>
        </div>
        {valueSending ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "8px 0",
            }}
          >
            <Progress
              percent={
                channelIds?.length === channelIdsSync?.length
                  ? 100
                  : valueSending.percent
              }
              showInfo={false}
              type="line"
              strokeColor={"#52c41a"}
            />
            <span
              style={{
                marginLeft: 8,
                whiteSpace: "nowrap",
              }}
            >
              {channelIds?.length === channelIdsSync?.length
                ? "Hoàn thành"
                : `${valueSending.number}/${channelIds?.length}`}
            </span>
          </div>
        ) : null}
        {channelIdsSync?.length > 0 ? (
          <div
            style={{
              padding: 16,
              backgroundColor: "white",
              borderRadius: 12,
            }}
          >
            <div
              style={{
                maxHeight: 190,
                overflow: "auto",
              }}
            >
              {allPage
                ?.filter(page => channelIdsSync.includes(page?.id))
                ?.map((item, index) => (
                  <div
                    className="item-page-success"
                    style={{
                      marginTop: index == 0 ? 0 : 4,
                    }}
                  >
                    <img src={IconSuccess} style={{ width: 24, height: 24 }} />
                    <div
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "500",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item?.name}
                      </div>
                      <div>Sao chép trả lời nhanh thành công</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 16px",
          borderTop: "1px solid #e8e8e8",
          borderRadius: "0 0 12px 12px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: "5px 8px",
              marginRight: 10,
            }}
          >
            <Radio
              onChange={onChangeMode}
              value={"UPDATE_DUPLICATE"}
              checked={modeValue === "UPDATE_DUPLICATE"}
            >
              <span style={{ fontSize: 16, fontWeight: "500" }}>
                Thêm vào danh sách hiện có
              </span>
            </Radio>
          </div>
          <div
            style={{
              padding: "5px 8px",
            }}
          >
            <Radio
              onChange={onChangeMode}
              value={"REPLACE_ALL"}
              checked={modeValue === "REPLACE_ALL"}
            >
              <span style={{ fontSize: 16, fontWeight: "500" }}>
                Thay thế danh sách hiện có
              </span>
            </Radio>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="btn-close-sync" onClick={onClose}>
            Đóng
          </div>
          <div
            className="btn-submit-sync"
            style={{
              backgroundColor:
                channelIds?.length > 0 && quickReplyIds?.length > 0
                  ? localStorage.getItem("colorTheme") || "#2f54eb"
                  : "#8596B4",
              cursor:
                channelIds?.length > 0 && quickReplyIds?.length > 0
                  ? "pointer"
                  : "no-drop",
            }}
            onClick={() => {
              setValueSending({
                number:
                  channelIds?.length === 1
                    ? 0
                    : Math.round(0.3 * channelIds?.length),
                percent: 30,
              });
              setChannelIdsSync([]);
              onSubmit();
            }}
          >
            Sao chép
          </div>
        </div>
      </div>
      {isOpenSortModal && (
        <SortQuickReplies
          isOpen={isOpenSortModal}
          quickRepliesList={allQuickReply || []}
          topicList={topicList || []}
          onClose={onCloseSortModal}
          onSuccess={onSuccessSort}
        />
      )}
    </Modal>
  );
};

export default styled(Index)`
  .channel-item {
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 8px;
    background-color: white;
  }
  .cancel-channel {
    display: none;
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .channel-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .option-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 8px;
    border-radius: 4px;
    &:hover {
      background-color: #f2f4f7;
    }
  }
  .option-content {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
  }
  .list-tag {
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 8px;
  }
  .search-tag {
    width: 250px;
  }
  .btn-close-sync {
    border-radius: 6px;
    background-color: #f2f4f7;
    color: #000;
    font-weight: 500;
    border: none;
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: #e2e6ee;
    }
  }
  .btn-submit-sync {
    display: flex;
    align-items: center;
    margin-left: 8px;
    border-radius: 6px;
    color: #fff;
    font-weight: 500;
    border: none;
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
  }
  .item-page-success {
    border-radius: 8px;
    padding: 8px;
    color: #1d2939;
    display: flex;
    gap: 8px;
    border: 1px solid #dde1e7;
  }
`;
