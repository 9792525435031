import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";

// components
import {
    Dropdown,
    Tooltip,
} from 'antd';
import {
    PaperClipOutlined,
    MessageFilled,
    EditOutlined,
} from '@ant-design/icons';
import {
    Row,
    Col,
} from "reactstrap";

// interface
import {
    getQuickRepliesList,
} from "../../../../api/index";

// styles
import styled from "styled-components";

// router
import { useNavigate } from "react-router-dom";

// utils
import {
    checkKeyInsert,
} from "../../../../utils";

interface IndexProps {
    onSetTxt: (txt: string) => void;
    onSetFileList: (fileList: any) => void;
    selectedConversation: any;
    channelSelected: any;
    className?: string;
}
const Index = ({
    className,
    selectedConversation,
    channelSelected,
    onSetTxt,
    onSetFileList,
}: IndexProps) => {
    const navigate = useNavigate();
    const [quickRepliesList, setQuickRepliesList] = useState<any>(null);
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);

    useEffect(() => {
        if (selectedConversation?.channelId) {
            getQuickRepliesListData({
                channelId: selectedConversation?.channelId || '',
                page: 1,
                limit: 50,
            });
        }
    }, [selectedConversation?.channelId]);

    const handleScrollToBottom = useCallback(() => {
        const element = document.getElementById("quickReplies-list");
        if (element) {
            const { scrollTop, clientHeight, scrollHeight } = element;
            if ((scrollTop + clientHeight + 1 >= scrollHeight) && quickRepliesList?.total > quickRepliesList?.items?.length) {
                getQuickRepliesListData({
                    channelIds: selectedConversation?.channelId || '',
                    page: quickRepliesList?.page + 1,
                    limit: 50,
                });
            }
        }
    }, [quickRepliesList, selectedConversation?.channelId]);

    useEffect(() => {
        const element = document.getElementById("quickReplies-list");
        if (element) {
            element.addEventListener("scroll", handleScrollToBottom);
        }
        return () => {
            if (element) {
                element.removeEventListener("scroll", handleScrollToBottom);
            }
        };
    }, [handleScrollToBottom]);

    const getQuickRepliesListData = async (data: any) => {
        try {
            const response: any = await getQuickRepliesList({ channelId: data.channelId, page: data?.page, limit: data?.limit });
            if (response?.data?.items) {
                const arr = quickRepliesList?.items && data.page !== 1 ? [...quickRepliesList?.items, ...response.data.items] : response.data.items;
                setQuickRepliesList({
                    total: response.data.total,
                    page: data.page,
                    items: arr,
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const onSelect = (quickReplies: any) => {
        setIsOpenDropdown(false);
        onSetTxt(quickReplies?.messages?.map((item: any) => checkKeyInsert(item?.message, selectedConversation, channelSelected)).join(' '));
        onSetFileList(quickReplies?.messages?.map((item: any) => item?.photos).flat().concat(quickReplies?.messages?.map((item: any) => item?.files).flat()));
    };

    const openDropdownQuickReplies = () => {
        return (
            <div style={{
                width: 545,
                height: 400,
                borderRadius: 6,
                backgroundColor: 'white',
                overflow: 'auto',
                boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.15)',
            }}>
                <div style={{ display: 'flex', padding: '10px 12px', alignItems: 'center', justifyContent: 'flex-end', borderBottom: '1px solid #d0d0d0' }}>
                    <span style={{ fontWeight: 'bold', color: '#263c8fed' }}>Mẫu trả lời nhanh</span>
                    <Tooltip title={'Sửa câu trả lời nhanh'}>
                        <div
                            style={{
                                marginLeft: 10,
                                paddingLeft: 10,
                                borderLeft: '1px solid #D9D9D9',
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate(`/setting/quick-replies?channelId=${selectedConversation?.channelId}`)}
                        >
                            <EditOutlined style={{ fontSize: 16, color: '#00000073' }} />
                        </div>
                    </Tooltip>
                </div>
                <div
                    className="bg-white"
                    id="quickReplies-list"
                    style={{
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}>
                    <Row className="align-items-center p-2" style={{
                        borderBottom: '1px solid #d0d0d0',
                        backgroundColor: '#F5F5F5'
                    }}>
                        <Col sm={1}>
                        </Col>
                        <Col sm={3}>
                            <div>
                                <span style={{ fontWeight: 'bold' }}>Kí tự tắt</span>
                            </div>
                        </Col>
                        <Col sm={8}>
                            <div>
                                <span style={{ fontWeight: 'bold' }}>Tin nhắn</span>
                            </div>
                        </Col>
                    </Row>
                    {
                        quickRepliesList?.items?.length > 0 ? (
                            quickRepliesList?.items?.map((quickReplies: any, key: number) => (
                                <Row
                                    key={quickReplies?.id}
                                    className="btn-tag align-items-center p-2"
                                    style={{
                                        borderBottom: '1px solid #d0d0d0',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => onSelect(quickReplies)}
                                >
                                    <Col sm={1}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <span>{key + 1}.</span>
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <span>{quickReplies?.shortcut}</span>
                                        </div>
                                    </Col>
                                    <Col sm={8} style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}>
                                        <div style={{
                                            width: '100%',
                                        }}>
                                            {
                                                quickReplies?.messages?.map((message: any, index: number) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            width: '100%',
                                                            marginTop: index === 0 ? 0 : 4
                                                        }}>
                                                        {
                                                            message?.photos?.length > 0 ? (
                                                                <div style={{
                                                                    position: 'relative',
                                                                    width: 24,
                                                                    height: 24,
                                                                    marginRight: 8,
                                                                    borderRadius: 6,
                                                                }}>
                                                                    <img src={message?.photos[0]?.location} style={{
                                                                        width: 24,
                                                                        height: 24,
                                                                        borderRadius: 6,
                                                                    }} />
                                                                    {
                                                                        message?.photos?.length > 1 ? (
                                                                            <Tooltip title={(
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                }}>
                                                                                    {
                                                                                        message?.photos?.map((photo: any, index: number) => (
                                                                                            <img
                                                                                                key={photo?.id}
                                                                                                src={photo?.location}
                                                                                                style={{
                                                                                                    width: 24,
                                                                                                    height: 24,
                                                                                                    borderRadius: 6,
                                                                                                    marginLeft: index == 0 ? 0 : 8,
                                                                                                }}
                                                                                            />
                                                                                        ))

                                                                                    }
                                                                                </div>
                                                                            )}>
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                    position: 'absolute',
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                    width: 24,
                                                                                    height: 24,
                                                                                    marginRight: 8,
                                                                                    borderRadius: 6,
                                                                                    backgroundColor: '#0000004d',
                                                                                }}>
                                                                                    <span style={{ color: 'white' }}>+{message?.photos?.length - 1}</span>
                                                                                </div>
                                                                            </Tooltip>
                                                                        ) : null
                                                                    }
                                                                </div>
                                                            ) : null
                                                        }
                                                        {
                                                          message?.files?.length > 0 ? (
                                                            <Tooltip title={
                                                              message?.files?.map((photo: any, index: number) => (
                                                                <div key={photo?.id}>{photo?.originalname}</div>
                                                              ))
                                                            }>
                                                              <div style={{
                                                                width: 14,
                                                                height: 14,
                                                                position: 'relative',
                                                                marginRight: 16,
                                                              }}>
                                                                <PaperClipOutlined
                                                                  style={{ fontSize: 14 }}
                                                                />
                                                                <div style={{
                                                                  fontSize: message?.files?.length > 99 ? 7 : 10,
                                                                  position: 'absolute',
                                                                  top: -6,
                                                                  right: -9,
                                                                  width: 16,
                                                                  height: 16,
                                                                  backgroundColor: 'red',
                                                                  borderRadius: '50%',
                                                                  color: 'white',
                                                                  display: 'flex',
                                                                  alignItems: 'center',
                                                                  justifyContent: 'center',
                                                                  fontWeight: 'bold',
                                                                  border: '1px solid white',
                                                                }}>
                                                                  {
                                                                    message?.files?.length > 99 ?
                                                                      "99+" :
                                                                      message?.files?.length
                                                                  }
                                                                </div>
                                                              </div>
                                                            </Tooltip>
                                                          ) : null
                                                        }
                                                        <Tooltip title={message?.message}>
                                                          <span style={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                          }}>{message?.message}</span>
                                                        </Tooltip>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            ))
                        ) : null
                    }
                </div>
            </div>
        );
    };

    return (
        <Dropdown
            className={className}
            open={isOpenDropdown}
            trigger={['click']}
            placement="topRight"
            getPopupContainer={(trigger: any) => trigger.parentNode}
            dropdownRender={() => openDropdownQuickReplies()}
            arrow={{
                pointAtCenter: true,
            }}
            onOpenChange={(open) => setIsOpenDropdown(open)}
        >
            <Tooltip title={'Trả lời nhanh'}>
                <div className="btn_chat">
                    <MessageFilled
                        style={{ fontSize: 20 }}
                    />
                </div>
            </Tooltip>
        </Dropdown>
    );
};

export default styled(Index)`
  .tag-select {
    .ant-select-selector {
      border: none !important;
      background: transparent !important;
      box-shadow: none !important;
    }
  }
  .btn_translate {
    position: absolute;
    right: 0px;
    top: 10px;
    cursor: pointer;
    color: #007bff;
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
    &:hover {
      text-decoration: underline;
    }
  }
  .avatar {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    border-radius: 3px;
  }
  .chat-input {
    padding: 10px 70px 10px 40px;
  }
  .btn_record {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    margin-left: 8px;
  }
  .badge {
    position: absolute;
    top: -9px;
    right: -9px;
    width: 16px;
    height: 16px;
    background-color: red;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 1px solid white;
  }
  .btn_chat {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    margin-left: 10px;
  }
  .icon_record {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 50px;
    background-color: red;
  }
  .btn_cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: white;
    width: 80px;
    height: 32px;
    font-weight: 500;
    margin-right: 10px;
    cursor: pointer;
  }
  .btn_send {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: blue;
    width: 80px;
    height: 32px;
    font-weight: 500;
    color: white;
    cursor: pointer;
  }
  .txt_time {
    text-align: center;
    color: white;
    font-weight: 500;
    margin-top: 10px;
  }
`
