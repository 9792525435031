import moment from "moment";
import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getConversationList = (params: any) => {
    let obj: any = {
        channelId: params.channelId?.split(','),
        page: params.page,
        limit: params.limit,
    };
    if (params.isReaded === false) {
        obj.isReaded = params.isReaded;
    }
    obj.isSpam = params.isSpam || false;
    if (params.isBaned) {
        obj.isBaned = params.isBaned;
    }
    if (params.startTime) {
        obj.startTime = moment(params?.startTime, "DD/MM/YYYY HH:mm:ss").valueOf();
    }
    if (params.endTime) {

        obj.endTime = moment(params?.endTime, "DD/MM/YYYY HH:mm:ss").valueOf();
    }
    if (params.tags) {
        obj.tags = params.tags;
    }
    if (params.exceptTags) {
        obj.exceptTags = params.exceptTags;
    }
    if (params.tagFilterMode) {
        obj.tagFilterMode = params.tagFilterMode;
    }
    if (params.timeFilterMode ) {
        obj.timeFilterMode = params.timeFilterMode ;
    }
    if (params.search) {
        obj.q = params.search;
    }
    if (params.type === 'INBOX' || params.type === 'COMMENT') {
        obj.type = params.type;
    }
    return api.create(url.GET_CONVERSATION_LIST + '/list', obj);
};

const addTagForConversation = (id: string, data: any) => {
    return api.create(url.GET_CONVERSATION_LIST + "/" + id + url.TAGS, data);
};


const getDetailConversation = (params: any) => {
    return api.get(url.GET_CONVERSATION_DETAIL + `/${params.conversationId}`);
};

const getDetailConversationFb = (params: any) => {
    return api.get(url.GET_CONVERSATION_DETAIL + `/${params.conversationId}/fb`);
};

const updateTranslationsConversation = (params: any) => {
    return api.update(url.GET_CONVERSATION_DETAIL + `/${params.conversationId}/translations`, params);
};
const getTranslationsLanguages = () => {
    return api.get(url.TRANSLATIONS_LANGUAGES, );
};

const readConversationId = (data: any) => {
    return api.create(`${url.GET_CONVERSATION_LIST}/${data?.conversationId}/make-readed`, data);
};


const onBanCustomer = (data: any) => {
    return api.create(`${url.CUSTOMERS}/${data?.conversationId}/toggle`, data);
};

const onSyncMessage = (data: any) => {
    return api.create(`${url.ON_SYNC}/${data?.channelId}/re-sync`, {});
};
export {
    getConversationList,
    addTagForConversation,
    getDetailConversation,
    updateTranslationsConversation,
    getTranslationsLanguages,
    readConversationId,
    onBanCustomer,
    getDetailConversationFb,
    onSyncMessage
};
